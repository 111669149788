/* eslint-disable require-jsdoc */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function HazardFilterComponent(props) {
  const [hazardFilters, setHazardFilters] = useState({
    distanceToCoast: {min: null, max: null, units: 'miles'},
    distanceToFloodZone: {min: null, max: null, units: 'miles'},
  });


  const handleDistanceToCoastChange = (e) => {
    const updatedValue = hazardFilters.distanceToCoast;
    updatedValue[e.target.name] = e.target.value == -1 ? null :e.target.value;
    setHazardFilters((hazardFilter) => ({
      ...hazardFilter,
      ...{distanceToCoast: updatedValue},
    }));
  };

  const handleDistanceToFloodZoneChange = (e) => {
    const updatedValue = hazardFilters.distanceToFloodZone;
    updatedValue[e.target.name] = e.target.value == -1 ? null :e.target.value;
    setHazardFilters((hazardFilter) => ({
      ...hazardFilter,
      ...{distanceToFloodZone: updatedValue},
    }));
  };

  useEffect(() => {
    props.onStateChange({hazardFilter: hazardFilters});
  }, [hazardFilters]);


  return (
   
        <Container>

          <Row className='mt-3'>

            <span className="">Distance To Coast</span>
            <div className="selectWrapper">
              <Form.Select name='min' aria-label="Distance To Coast Min" id="distance-to-coast-min" size="sm" onChange={handleDistanceToCoastChange}>
                <option value="-1">No Min</option>
                <option value="0.094697">500 feet</option>
                <option value="1">1 mile</option>
                <option value="2">2 miles</option>
                <option value="5">5 miles</option>
                <option value="10">10 miles</option>
              </Form.Select >
              <Form.Select name="max" id="distance-to-coast-max" aria-label="Distance To Coast Max" size="sm" onChange={handleDistanceToCoastChange} >
                <option value="-1">No Max</option>
                <option value="0.094697">500 feet</option>
                <option value="1">1 mile</option>
                <option value="2">2 miles</option>
                <option value="5">5 miles</option>
                <option value="10">10 miles</option>

              </Form.Select>
            </div>

          </Row>
          <Row className='mt-3 mb-3'>

            <span className="">Distance To FloodZone</span>
            <div className="selectWrapper">
              <Form.Select name='min' aria-label="Distance To FloodZone Min" id="distance-to-floodzone-min" size="sm" onChange={handleDistanceToFloodZoneChange}>
                <option value="-1">No Min</option>
                <option value="0.094697">500 feet</option>
                <option value="1">1 mile</option>
                <option value="2">2 miles</option>
                <option value="5">5 miles</option>
                <option value="10">10 miles</option>
              </Form.Select >
              <Form.Select name="max" id="distance-to-floodzone-max" aria-label="Distance To Flood Zone Max" size="sm" onChange={handleDistanceToFloodZoneChange} >
                <option value="-1">No Max</option>
                <option value="0.094697">500 feet</option>
                <option value="1">1 mile</option>
                <option value="2">2 miles</option>
                <option value="5">5 miles</option>
                <option value="10">10 miles</option>

              </Form.Select>
            </div>

          </Row>
        </Container>);
}


HazardFilterComponent.propTypes = {
  onStateChange: PropTypes.func,
};

export default HazardFilterComponent;
