import React, { ChangeEvent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import { Button } from '@mui/material';
import { Interfaces as AgencyPipeInterfaces } from 'agencypipe_commonlib';
import './css/NotesComponenetStyleSheet.css';

type NotesComponentProps = {
  showPropertyNotes: boolean;
  handleCloseNotes: () => void;
  selectedItemLeadEntry: AgencyPipeInterfaces.LeadTypes.ILead | null | undefined;
  loadPropertyNotes: () => void;
  selectedItem: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null;
  getHeaders: () => HeadersInit;
};

const NotesComponent = (props: NotesComponentProps) => {
  const [newNoteText, setNewNoteText] = useState('');
  const handleNewNoteTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewNoteText(e.currentTarget.value);
  };
  const handleAddNewNoteClick = async () => {
    const newNote = {
      note: newNoteText
    };
    const addNewNoteResponse = await fetch(`/api/leads/${props.selectedItemLeadEntry?._id}/notes`, {
      method: 'POST',
      headers: props.getHeaders(),
      body: JSON.stringify(newNote)
    });
    setNewNoteText('');
    const addNewNoteResponseJson = await addNewNoteResponse.json();
    if (addNewNoteResponseJson?.response?.success) {
      props.loadPropertyNotes();
    }
  };

  return (
    <Modal show={props.showPropertyNotes} onHide={props.handleCloseNotes} onShow={props.loadPropertyNotes} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Table striped hover responsive={true} className='table-fit'>
              <thead>
                <tr>
                  <th className='fit'>#</th>
                  <th className='fit'>Created</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>

                {props.selectedItemLeadEntry?.notes?.map((note, index) => {
                  return (
                    <tr>
                      <td className='fit'>{index + 1}</td>
                      <td className='fit'>{moment(note?.date).format('MM/DD/YYYY')}</td>
                      <td className='fit'>{note?.note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={10}>
            <input
              id="affiliate-search-form"
              name="search"
              placeholder="Add Note"
              type="text"
              className="search hide-lastpass w-100"
              data-lpignore="true"
              autoComplete="false"
              value={newNoteText}
              onChange={handleNewNoteTextChange}
            />
          </Col>
          <Col>
            <Button id="button-addon2" onClick={handleAddNewNoteClick}>
              Add Note
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default NotesComponent;
