import { Interfaces as AgencyPipeInterfaces } from "agencypipe_commonlib";
import React, { useState } from "react";
import { Row, Col, Form } from 'react-bootstrap';

type LocationProfileComponentProps = {
    userAddress?: AgencyPipeInterfaces.GeocodeTypes.ILocation
}
const LocationProfileComponent = (props: LocationProfileComponentProps) => {
    const userAddress = props.userAddress;
    const [addressLine, setAddressLine] = useState(userAddress?.addressLine1);
    const [addressLine2, setAddressLine2] = useState(userAddress?.addressLine2);
    const [addressCity, setAddressCity] = useState(userAddress?.city);
    const [addressState, setAddressState] = useState(userAddress?.state);
    const [addressZipCode, setAddressZipCode] = useState(userAddress?.postalCode);


    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-road"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="formUserAddressLine1" className='position-relative'>
                    <Form.Control
                        type="text"
                        onChange={(e) => setAddressLine(e.target.value)}
                        placeholder="Address"
                        value={addressLine} />
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-road"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="formUserAddressLine2" className='position-relative'>
                    <Form.Control
                        type="text"
                        onChange={(e) => setAddressLine2(e.target.value)}
                        placeholder="Address Line 2"
                        value={addressLine2} />
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-city"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="formUserAddressCity" className='position-relative'>
                    <Form.Control
                        type="text"
                        onChange={(e) => setAddressCity(e.target.value)}
                        placeholder="City"
                        value={addressCity} />
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-flag-usa"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="formUserAddressState" className='position-relative'>
                    <Form.Control
                        type="text"
                        onChange={(e) => setAddressState(e.target.value)}
                        placeholder="State"
                        value={addressState} />
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-brands fa-usps"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="formUserAddressZipCode" className='position-relative'>
                    <Form.Control
                        type="text"
                        onChange={(e) => setAddressZipCode(e.target.value)}
                        placeholder="ZipCode"
                        value={addressZipCode} />
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}
export default LocationProfileComponent;