import React from 'react';
import { Row } from 'react-bootstrap';
import FadeInSection from './FadeInSection';
import Card from 'react-bootstrap/Card';

function FAQSectionComponent() {
    return (
        <FadeInSection>
            <Row id="faq" className='scrollable pageContainer' >
                <Card>
                    <Card.Body>
                        <Card.Title>Can I customize the SaaS platform to fit my business needs?</Card.Title>
                        <Card.Text>Absolutely! Our SaaS platform is designed to be highly customizable, allowing you to tailor it to your specific business needs and requirements.</Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>How often are updates and new features released on the SaaS platform?</Card.Title>
                        <Card.Text>We regularly release updates and new features to our SaaS platform to ensure continuous improvement and innovation.</Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>What are the benefits of using a SaaS solution?</Card.Title>
                        <Card.Text>SaaS solutions offer flexibility, scalability, cost-effectiveness, and easy accessibility, enabling streamlined operations and enhanced productivity.</Card.Text>
                    </Card.Body>
                </Card>
            </Row>
        </FadeInSection>);
}

export default FAQSectionComponent;