import React, { useState } from 'react';
import {
    MDBBtn,
    MDBInput,
} from 'mdb-react-ui-kit';
import emailjs from '@emailjs/browser';

const PassReset = () => {
    const [emailAddress, setEmailAddress] = useState('');
    const [passToken, setPassToken] = useState('');
    const [matchVis, setMatchVis] = useState(false);
    const [codeMatch, setCodeMatch] = useState('');
    const [doPassMatch, setDoPassMatch] = useState(false);
    const [newPass1, setNewPass1] = useState('');
    const [newPass2, setNewPass2] = useState('');

    const sendEmail = () => {
        const fetchData = async () => {
            console.log(emailAddress);

            try {
                const response = await fetch("/auth/find", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: emailAddress,
                        password: 'dummy_password'
                    }),
                });

                const data = await response.json();
                console.log(data);
                if (data && data.token) {
                    const params = {
                        recipient: emailAddress,
                        tempCode: genPassToken()
                    };
                    emailjs.send('service_syivqph', 'template_pespdd1', params, 'oIMOsWirwO1_9Y9jc');
                    setMatchVis(true);
                }
            } catch (error) {
                alert("Invalid email");
            }

        };

        fetchData();
    };

    const checkCodeToPass = () => {
        if (passToken === codeMatch) {
            setDoPassMatch(true);
        }
        else {
            alert("Entered code is invalid, Please try again.");
        }
    }

    const setPass = () => {
        if (!(newPass1 === newPass2)) {
            alert("Passwords do not match. Please try again.");
            return;
        }

        const fetchData = async () => {
            const credentials = {
                email: emailAddress,
                password: newPass1
            }

            console.log(credentials);

            const response = await fetch('/auth/update/password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();
            if (data && data.token) {
                //props.onSignInSuccess(data);
            } else {
                alert('Invalid Email Address or Password');
            }
        };
        fetchData();
    }

    const genPassToken = () => {
        const charList = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890';
        let tempPass = '';
        for (let i = 0; i < 10; i++) {
            tempPass = tempPass + charList.charAt(Math.random() * charList.length);
        }
        setPassToken(tempPass);
        return tempPass;
    }

    return (
        <div>
            Enter email:
            <MDBInput wrapperClass='mb-4' label='Email' id='accountEmail' type='email' onChange={(e) => setEmailAddress(e.target.value)} />
            <MDBBtn color='primary' onClick={sendEmail}> Send </MDBBtn>
            {matchVis && <MDBInput wrapperClass='mb-4' label='Recovery Code' id='recoveryCode' type='string' onChange={(e) => setCodeMatch(e.target.value)} />}
            {matchVis && <MDBBtn color='primary' onClick={checkCodeToPass}> Verify </MDBBtn>}
            {doPassMatch && <MDBInput wrapperClass='mb-4' label='Enter New Password' id='np1' type='password' onChange={(e) => setNewPass1(e.target.value)} />}
            {doPassMatch && <MDBInput wrapperClass='mb-4' label='Confirm Password' id='np2' type='password' onChange={(e) => setNewPass2(e.target.value)} />}
            {doPassMatch && <MDBBtn color='primary' onClick={setPass}> Set New Password </MDBBtn>}
        </div>
    );
};

export default PassReset;