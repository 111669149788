import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import FadeInSection from './FadeInSection';
import Form from 'react-bootstrap/Form';

function AboutUsSectionComponent() {
    return (<FadeInSection>
        <Row id="about-us" className="scrollable">
            <Container id="aboutUsContainer">
                <Row>
                    <Col>
                        <p id="aboutUsWhyAgencyPipe">WHY AGENCY PIPE</p>
                        <p id="aboutUsTargetProspects">Targeted Prospects We're a SAAS company dedicated to helping insurance agents like you target prospects which fit your underwriting criteria</p>
                        <ul id="aboutUsList">
                            <li>Higher Hit Ratio</li>
                            <li>Lower Marketing Costs</li>
                            <li>Drive Target Business</li>
                        </ul>
                    </Col>
                    <Col id="aboutUsContactCol">
                        <h3>Contact Us Now</h3>
                        <Form>
                            <Form.Group className="mb-3" controlId="formFullName">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" placeholder="Bruce Wayne" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formAgencyName">
                                <Form.Label>Agency Name</Form.Label>
                                <Form.Control type="text" placeholder="Wayne Enterprises" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmailAddress">
                                <Form.Label>Email Name</Form.Label>
                                <Form.Control type="email" placeholder="brucewayne@mail.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" placeholder="(555) 122-8626" />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Row>
    </FadeInSection>)
}

export default AboutUsSectionComponent;