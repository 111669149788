/* eslint-disable require-jsdoc */
import React, {useState, useEffect} from 'react';
import {Container, Row} from 'react-bootstrap';

import PropTypes from 'prop-types';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';

export default function PermitsFilterComponent(props) {
  const [buildingPermitTypes, setBuildingPermitTypes] = useState([]);
  const [permitAttributes, setPermitAttributes] = useState([]);

  useEffect(() => {
    props.onStateChange({permitFilter: permitAttributes});
  }, [permitAttributes]);

  useEffect(() => {
    setBuildingPermitTypes(
        props.dataSets?.permits?.classifiers?.map((classifier) => {
          return {label: classifier,value: classifier};
        }),
    );
  }, [props.dataSets]);
  const animatedComponents = makeAnimated();

function handleChange (selectedOption)  {

  const permitFilterMap = selectedOption.map((option) => {
    return {
      name: option.value
    };
  })

   setPermitAttributes(permitFilterMap);
  }

  return (
   
          <Container>


            <Row className='mt-3 mb-3'>
               <Select id='propertyUseCodeMultiSelect'
                placeholder='Permit Types'
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={buildingPermitTypes}
                onChange={handleChange}
              />
            </Row>
          </Container>
    
  );
}

  PermitsFilterComponent.propTypes = {
    onStateChange: PropTypes.func,
    dataSets: PropTypes.object
  };

