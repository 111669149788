import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define the type for the context state
interface MapContextType {
    mapCenter: { lat: number; lng: number };
    mapZoom: number;
    setMapZoom: (zoom: number) => void;
    setMapCenter: (center: { lat: number; lng: number }) => void;
    markers: { center: { lat: number; lng: number }, onClick: (e: google.maps.MapMouseEvent) => void }[];
    addMarker: (marker: { center: { lat: number; lng: number }, onClick: (e: google.maps.MapMouseEvent) => void }) => void;
    clearMarkers: () => void;
}

// Create a Context with a default value
const MapContext = createContext<MapContextType | undefined>(undefined);

// Create a provider component
export const MapProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
        lat: 35.95078159971039,
        lng: -93.30989249999999
    });
    const [markers, setMarkers] = useState<{
        center: { lat: number; lng: number },
        onClick: (e: google.maps.MapMouseEvent) => void
    }[]>([]);
    const [mapZoom, setMapZoom] = useState<number>(4);

    // Expose the state to the window object
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).mapState = {
            mapCenter,
            setMapCenter,
            mapZoom,
            setMapZoom,
            markers,
            addMarker: addMarker,
            clearMarkers: clearMarkers
        };
    }, [mapCenter]);

    const addMarker = (marker: { center: { lat: number; lng: number }, onClick: (e: google.maps.MapMouseEvent) => void }) => {
        setMarkers((prevMarkers) => [...prevMarkers, marker]);
    };
    const clearMarkers = () => {
        setMarkers([]);
    }

    return (
        <MapContext.Provider value={{ mapCenter, setMapCenter, mapZoom, setMapZoom, markers, addMarker, clearMarkers }}>
            {children}
        </MapContext.Provider>
    );
};

// Custom hook to use the Map context
export const useMap = (): MapContextType => {
    const context = useContext(MapContext);
    if (!context) {
        throw new Error('useMap must be used within a MapProvider');
    }
    return context;
};
