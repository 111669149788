import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FadeInSection from './FadeInSection';
import Card from 'react-bootstrap/Card';

function PricingSectionComponent() {
    return (
        <FadeInSection>
            <Row id="pricing" className="scrollable">
                <Container className="pageContainer">
                    <Card>
                        <Row>
                            <Col>
                                <h2>Basic</h2>
                                <h3>CALL US</h3>
                                <p>Identify and Export Targeted Prospects Plan features Property Data & Hazard Data Single State Email Support</p>
                            </Col>
                            <Col>
                                <h2>Premium</h2>
                                <h3>CALL US</h3>
                                <p>Identify and Automate Target Prospect Marketing Plan features Property Data, Hazard Data, & Automation Tools Multi-State Email Support</p>
                            </Col>
                            <Col>
                                <h2>Enterprise</h2>
                                <h3>CALL US</h3>
                                <p>Custom Automation or Carrier Integrations Plan features Custom Data No Geographic Limitations Email & Phone Support</p>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col>
                            <Card className="mt-5">
                                <Card.Body>

                                    <Card.Title>Focused Prospecting</Card.Title>
                                    <Card.Img className="featuresIcon" variant="top" src="img/focused_prospecting.svg" />
                                    <Card.Text>Target The Prospects You Know Your Carriers Will Write</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mt-5">
                                <Card.Body>

                                    <Card.Title>High Probability</Card.Title>
                                    <Card.Img className="featuresIcon" variant="top" src="img/high_sales.svg" />
                                    <Card.Text>Sales Target Risks With High Probability Closing Rates</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mt-5">
                                <Card.Body>
                                    <Card.Title>Cross Selling</Card.Title>
                                    <Card.Img className="featuresIcon" variant="top" src="img/cross_selling_opportunities.svg" />
                                    <Card.Text>Cross Sell Additional Policies Using Multiple Datasets</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mt-5">
                                <Card.Body>
                                    <Card.Title>Simple Integration</Card.Title>
                                    <Card.Img className="featuresIcon" variant="top" src="img/simple_integrations.svg" />
                                    <Card.Text>Write Business Without Manual Quoting</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </FadeInSection>);
}

export default PricingSectionComponent;