import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FadeInSection from './FadeInSection';
import Button from '@mui/material/Button';


function HomeSectionComponent() {

    return (
        <FadeInSection >
            <div className="homescrollable">
                <Row id="home" className="justify-content-center ">
                    Empower Your Agency With
                </Row>
                <Row id="divNextGenText" className="justify-content-center">
                    Next-Generation Marketing Solutions
                </Row>
                <Row id="divRevProspecting" className="justify-content-center">
                    Revolutionize Your Prospecting, Automate Your Process, and Grow Your Revenue with our Targeted Marketing Solutions
                </Row>
                <Row className="mt-5">
                    <Col xs={6}>
                        <Button className="ms-5 me-5" color="primary" variant="contained">
                            Let's Get Started
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button className="ms-5 me-5" color="primary" variant="contained">
                            Request A Demo
                        </Button>
                    </Col>
                </Row>
            </div>
        </FadeInSection >)
}

export default HomeSectionComponent;