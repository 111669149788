import React, { ChangeEventHandler } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import CopeFilterComponent from '../filters/CopeFilter';
import HazardFilterComponent from '../filters/HazardFilter';
import PermitsFilterComponent from '../filters/PermitsFilter';
import Form500Filter from '../filters/Form5500Filter';

type FiltersComponenentProps = {
    toggleFilterShow: () => void,
    dataSets: object,
    handleFilterChange: (updateFilter: object) => void,
    handleCostPerSqFootChange: ChangeEventHandler<HTMLInputElement>,
    costPerSqFoot: number
}

function FiltersComponenent(props: FiltersComponenentProps) {

    return (<Container fluid>
        <Row>
            <Col><span>Search Filters</span>
                <button type="button" className="btn-close float-end" aria-label="Close" onClick={props.toggleFilterShow}></button>
            </Col>
        </Row>
        <Row>
            <Col>
                <Accordion defaultActiveKey="property-characteristics-item" flush>
                    <Accordion.Item eventKey="property-characteristics-item">
                        <Accordion.Header>Property Characteristics</Accordion.Header>
                        <Accordion.Body>
                            <input type='text' placeholder='Cost Per Sq Foot' value={props.costPerSqFoot} onChange={props.handleCostPerSqFootChange}></input>
                            <CopeFilterComponent dataSets={props.dataSets} onStateChange={props.handleFilterChange}></CopeFilterComponent>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="commerical-item">
                        <Accordion.Header>Commercial</Accordion.Header>
                        <Accordion.Body>
                            <Form500Filter onStateChange={props.handleFilterChange} visible={true}></Form500Filter>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="hazard-item">
                        <Accordion.Header>Hazard Data</Accordion.Header>
                        <Accordion.Body>
                            <HazardFilterComponent onStateChange={props.handleFilterChange}></HazardFilterComponent>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="permits-item">
                        <Accordion.Header>Permits</Accordion.Header>
                        <Accordion.Body>
                            <PermitsFilterComponent onStateChange={props.handleFilterChange} dataSets={props.dataSets}></PermitsFilterComponent>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    </Container >);
}

export default FiltersComponenent;