/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable require-jsdoc */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import './CopeFilter.css';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

//import MultiCheckboxFilter from './MultiCheckboxFilter';

export default function CopeFilterComponent(props) {
  const [copeAttributes, setCopeAttributes] = useState({
    salePrice: {
      min: null,
      max: null,
    },
    livingAreaSquareFeet: {
      min: null,
      max: null,
    },
    yearBuilt: {
      min: null,
      max: null,
    },

  });


  const handlePriceChange = (e) => {
    e.preventDefault();
    const updatedValue = copeAttributes.salePrice;
    let price = e.target.value;
    price = price.replace('$', '');
    price = price.replace(',', '');
    if (price == 'Any Price') {
      price = null;
    }
    updatedValue[e.target.name] = price;
    setCopeAttributes((copeAttributes) => ({
      ...copeAttributes,
      ...{ salePrice: updatedValue },
    }));
  };


  function handleSqFtChange(e) {
    e.preventDefault();
    const updatedValue = copeAttributes.livingAreaSquareFeet;
    updatedValue[e.target.name] = e.target.value == -1 ? null : e.target.value;
    setCopeAttributes((copeAttributes) => ({
      ...copeAttributes,
      ...{ livingAreaSquareFeet: updatedValue },
    }));
  }

  function handleYearBuiltChange(e) {
    e.preventDefault();
    const updatedValue = copeAttributes.yearBuilt;
    updatedValue[e.target.name] = e.target.value == -1 ? null : e.target.value;
    setCopeAttributes((copeAttributes) => ({
      ...copeAttributes,
      ...{ yearBuilt: updatedValue },
    }));
  }


  const handlePropertyUseCodeChange = (e) => {
    setCopeAttributes((copeAttributes) => ({
      ...copeAttributes,
      ...e,
    }));
  };


  useEffect(() => {
    props.onStateChange({ copeFilter: copeAttributes });
  }, [copeAttributes]);


  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const propertyUseCodeMultiSelectOptions = props?.dataSets?.propertyUseCodes?.map((propCode) => {
      return {
        value: propCode,
        label: propCode
      }
    });
    setOptions(propertyUseCodeMultiSelectOptions);
  }, [props?.dataSets?.propertyUseCode]);

  return (

    <Container>

      <Row>
        {/* <MultiCheckboxFilter onStateChange={handlePropertyUseCodeChange} items={props.dataSets.propertyUseCodes} label='propertyUseCodes' name="Property Use Codes" allMultiple={true}></MultiCheckboxFilter> */}
        <div>
          <Select id='propertyUseCodeMultiSelect'
            placeholder='Property Use Codes'
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={options}
          />
        </div>
      </Row>

      <Row className='mt-3'>

        <span className="">Square feet</span>
        <div className="selectWrapper">
          <Form.Select name='min' aria-label="Square feet min" id="sqft-min" size="sm" onChange={handleSqFtChange}>
            <option value="-1">No Min</option>
            <option value="500">500</option>
            <option value="750">750</option>
            <option value="1000">1,000</option>
            <option value="1250">1,250</option>
            <option value="1500">1,500</option>
            <option value="1750">1,750</option>
            <option value="2000">2,000</option>
            <option value="2250">2,250</option>
            <option value="2500">2,500</option>
            <option value="2750">2,750</option>
            <option value="3000">3,000</option>
            <option value="3500">3,500</option>
            <option value="4000">4,000</option>
            <option value="5000">5,000</option>
            <option value="7500">7,500</option>
          </Form.Select >
          <Form.Select name="max" id="sqft-max" aria-label="Square feet max" size="sm" onChange={handleSqFtChange} >
            <option value="-1">No Max</option>
            <option value="500">500</option>
            <option value="750">750</option>
            <option value="1000">1,000</option>
            <option value="1250">1,250</option>
            <option value="1500">1,500</option>
            <option value="1750">1,750</option>
            <option value="2000">2,000</option>
            <option value="2250">2,250</option>
            <option value="2500">2,500</option>
            <option value="2750">2,750</option>
            <option value="3000">3,000</option>
            <option value="3500">3,500</option>
            <option value="4000">4,000</option>
            <option value="5000">5,000</option>
            <option value="7500">7,500</option>

          </Form.Select>
        </div>

      </Row>


      <Row className='mt-3'>
        <span className="">Year Built</span>
        <div className="selectWrapper">
          <Form.Select name="min" aria-label="Year Built Min" id="yrbuilt-min" size="sm" onChange={handleYearBuiltChange}>
            <option value="-1">No Min</option>
            <option value="1900">1900</option>
            <option value="1950">1950</option>
            <option value="2000">2000</option>
            <option value="2010">2010</option>
            <option value="2020">2020</option>
          </Form.Select >
          <Form.Select name="max" id="yrbuilt-max" aria-label="Year Built Max" size="sm" onChange={handleYearBuiltChange}>
            <option value="-1">No Max</option>
            <option value="1900">1900</option>
            <option value="1950">1950</option>
            <option value="2000">2000</option>
            <option value="2010">2010</option>
            <option value="2020">2020</option>
            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>

          </Form.Select>
        </div>
      </Row>
      <Row className='mt-3'>
        <span className="">Sale Price</span>
        <Container fluid>
          <Row>
            <Col lg={6}>
              <input name="min" className="form-control form-select form-select-sm saleprice-input" list="minPriceOptions" id="salePriceMin" placeholder="No Min" onChange={handlePriceChange}></input>
              <datalist id="minPriceOptions">
                <option value="$0"></option>
                <option value="$100,000"></option>
                <option value="$200,000"></option>
                <option value="$300,000"></option>
                <option value="$400,000"></option>
                <option value="$500,000"></option>
                <option value="$600,000"></option>
                <option value="$700,000"></option>
                <option value="$800,000"></option>
                <option value="$900,000"></option>
              </datalist>
            </Col>
            <Col lg={6}>
              <input name="max" className="form-control form-select form-select-sm saleprice-input" list="maxPriceOptions" id="salePriceMax" placeholder="No Max" onChange={handlePriceChange}></input>
              <datalist id="maxPriceOptions">
                <option value="$0"></option>
                <option value="$500,000"></option>
                <option value="$600,000"></option>
                <option value="$700,000"></option>
                <option value="$800,000"></option>
                <option value="$900,000"></option>
                <option value="$1M"></option>
                <option value="$1.25M"></option>
                <option value="$1.75M"></option>
                <option value="Any Price"></option>
              </datalist>
            </Col>
          </Row>

        </Container>
      </Row>



    </Container>


  );
}

CopeFilterComponent.propTypes = {
  onStateChange: PropTypes.func,
  dataSets: PropTypes.object,
};
