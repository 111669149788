import React, { useState, useEffect } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { Icon } from 'react-icons-kit';

const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
const LOWERCASE_REGEX = new RegExp(/.*[a-z]/)
const NUMBER_REGEX = new RegExp(/.*\d/);
const LENGTH_REGEX = new RegExp(/.{8,}/);
// eslint-disable-next-line no-useless-escape
const SPECIAL_CHARS_REGEX = new RegExp(/.*[!@#$%^&*\(\)_+\{\}\[\]:;<>,\.?~\-\\=\/`]/); // escape chars are in fact needed

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PASSWORD_VALID_REGEX = new RegExp(
    `^(?=${[UPPERCASE_REGEX.source,
    LOWERCASE_REGEX.source,
    NUMBER_REGEX.source,
    SPECIAL_CHARS_REGEX.source,
    LENGTH_REGEX.source
    ].join(")(?=")}).*$`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PASSWORD_VALID_REGEX2 = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/;
const rules = [
    { label: "One Uppercase", pattern: UPPERCASE_REGEX },
    { label: "One Lowercase", pattern: LOWERCASE_REGEX },
    { label: "One Number", pattern: NUMBER_REGEX },
    { label: "Length Of 8 or Greater", pattern: LENGTH_REGEX },
    { label: "Special Character", pattern: SPECIAL_CHARS_REGEX },

]

type PasswordAndConfirmationComponentProps = {
    registrationPassword: string,
    handleSetRegistrationPassword: (arg: string) => void

}

const PasswordAndConfirmationComponent = (props: PasswordAndConfirmationComponentProps) => {
    const [registrationPasswordConfirmationIcon, setRegistrationPasswordConfirmationIcon] = useState(eyeOff);
    const [registrationPasswordIcon, setRegistrationPasswordIcon] = useState(eyeOff);
    const [registrationPasswordConfirmationType, setRegistrationPasswordConfirmationType] = useState('password');
    const [registrationPasswordType, setRegistrationPasswordType] = useState('password');
    const [registrationConfirmationPattern, setRegistrationPasswordConfirmationPattern] = useState('');
    const [registrationPasswordConfirmation, setRegistrationPasswordConfirmation] = useState('');

    const handleToggleRegistrationPasswordConfirmation = () => {
        if (registrationPasswordConfirmationType === 'password') {
            setRegistrationPasswordConfirmationIcon(eye);
            setRegistrationPasswordConfirmationType('text')
        } else {
            setRegistrationPasswordConfirmationIcon(eyeOff)
            setRegistrationPasswordConfirmationType('password')
        }
    }

    const handleToggleRegistrationPassword = () => {
        if (registrationPasswordType === 'password') {
            setRegistrationPasswordIcon(eye);
            setRegistrationPasswordType('text')
        } else {
            setRegistrationPasswordIcon(eyeOff)
            setRegistrationPasswordType('password')
        }
    }

    const getPasswordMatchRegEx = () => {

        const passwordEscaped = props.registrationPassword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        setRegistrationPasswordConfirmationPattern(passwordEscaped)
    }
    useEffect(() => {
        getPasswordMatchRegEx();
    }, [props.registrationPassword, registrationPasswordConfirmation])

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-lock"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="registrationPassword" className='position-relative'>
                    <Form.Control
                        required
                        type={registrationPasswordType}
                        onChange={(e) => props.handleSetRegistrationPassword(e.target.value)}
                        // eslint-disable-next-line no-useless-escape
                        //pattern={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_+\{\}\[\]:;<>,\.?~\-\\=\/`]).{8,}$/.source}
                        // eslint-disable-next-line no-useless-escape
                        pattern={PASSWORD_VALID_REGEX.source}
                        placeholder="Password" />
                    <Form.Control.Feedback type='invalid' tooltip>
                        {
                            rules.map((rule) => {
                                if (!props.registrationPassword.match(rule.pattern))
                                    return <div key={rule.label} className="invalid-regex-rule"><i className="fa-solid fa-x" ></i>{rule.label}</div>
                                else
                                    return <div key={rule.label} className="valid-regex-rule"><i className="fa-solid fa-check" ></i>{rule.label}</div>

                            })
                        }
                    </Form.Control.Feedback>
                </Form.Group>
                <Col xs="1">
                    <span className="flex justify-around items-center" onClick={handleToggleRegistrationPassword}>
                        <Icon className="passwordToggleIcon absolute mr-10" icon={registrationPasswordIcon} size={25} />
                    </span>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-key"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="registrationPasswordConfirmation">
                    <Form.Control
                        required

                        type={registrationPasswordConfirmationType}
                        onChange={(e) => { setRegistrationPasswordConfirmation(e.target.value); }}
                        placeholder="Confirmation Password"
                        pattern={`^${registrationConfirmationPattern}$`}
                    />
                    <Form.Control.Feedback type='invalid'>Passwords do not match</Form.Control.Feedback>

                </Form.Group>
                <Col xs="1">
                    <span className="flex justify-around items-center" onClick={handleToggleRegistrationPasswordConfirmation}>
                        <Icon className="passwordToggleIcon absolute mr-10" icon={registrationPasswordConfirmationIcon} size={25} />
                    </span>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default PasswordAndConfirmationComponent;