import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Landing from './components/landing/LandingComponent';
import MainAppComponent from './components/main/MainAppComponent';
import { ApplicationContextType } from './types/LoggedInTypes';

import OrderConfirmationComponent from './components/landing/OrderConfirmationComponent';
import { Interfaces as AgencyPipeInterfaces } from "agencypipe_commonlib";
import SignInSignUpComponent from './components/signInSignUp/SignInSignUpComponent';
import { MapProvider } from './components/maps/MapContext';

function App() {
  const [user, setUser] = useState<AgencyPipeInterfaces.UserTypes.AgencyPipeUser | null>(null);
  const [token, setToken] = useState<string | null>(null);


  function Root() {
    const navigate = useNavigate();
    function onSignInSuccess(user: AgencyPipeInterfaces.UserTypes.AgencyPipeUser, token: string) {
      if (user && token) {

        setUser(user);
        setToken(token);
        navigate('/app', { replace: true });
      }
    }
    function onInvalidSession() {
      navigate("/", { replace: true })
    }

    const createApplicationContext = (): ApplicationContextType => {
      return {
        user: user,
        token: token,
        onInvalidSession: onInvalidSession,
        navigator: navigate
      };
    }

    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<Landing onSignInSuccess={onSignInSuccess} />} />
          <Route path="/app" element={<MainAppComponent applicationContext={createApplicationContext()} />} />
          <Route path="/confirmation" element={<OrderConfirmationComponent></OrderConfirmationComponent>} />
          <Route path="/signin" element={<SignInSignUpComponent onSignInSuccess={onSignInSuccess} />} />
        </Routes>
      </React.Fragment>

    )
  }


  return (
    <div className="App">
      <BrowserRouter>
        <MapProvider>
          <Root></Root>
        </MapProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
