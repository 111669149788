/* eslint-disable no-useless-escape */

import React from "react";
import { Row, Col, Form } from 'react-bootstrap';

type BasicAccountInformationComponentProps = {
    handleSetFullName: (args: string) => void,
    handleSetEmail?: (args: string) => void,
    handleSetCompanyName: (args: string) => void,
    handleSetPhoneNumber?: (args: string) => void,

    fullName?: string,
    emailAddress?: string,
    companyName?: string,
    phoneNumber?: string


}
const BasicAccountInformationComponent = (props: BasicAccountInformationComponentProps) => {
    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-user"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="registrationName" className='position-relative'>
                    <Form.Control
                        required
                        type="text"
                        onChange={(e) => props.handleSetFullName(e.target.value)}
                        pattern={/^(?:[a-zA-Z]+\.\s+)?[a-zA-Z]+(?:[ '\-][a-zA-Z]+)+$/.source}
                        placeholder="Fulll Name"
                        value={props.fullName} />
                    <Form.Control.Feedback type='invalid' tooltip>Full Name is Required</Form.Control.Feedback>

                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-envelope"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="registrationEmailAddress" className='position-relative'>
                    <Form.Control
                        required
                        type="email"
                        disabled={props.handleSetEmail == null}
                        onChange={(e) => { if (props.handleSetEmail) props.handleSetEmail(e.target.value) }}
                        pattern={/^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/.source}
                        placeholder="Email Address"
                        value={props.emailAddress} />
                    <Form.Control.Feedback type='invalid' tooltip>A Valid Email Address is Required</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-4">
                <Col xs="1" className="inputIconCol">
                    <i className="fa-solid fa-building"></i>
                </Col>
                <Form.Group as={Col} xs={10} controlId="registrationCompanyName" className='position-relative'>
                    <Form.Control
                        type="text"
                        onChange={(e) => props.handleSetCompanyName(e.target.value)}
                        placeholder="Company Name"
                        value={props.companyName} />
                </Form.Group>
            </Row>
            {props.handleSetPhoneNumber != null ?
                <Row className="mb-4">
                    <Col xs="1" className="inputIconCol">
                        <i className="fa-solid fa-phone"></i>
                    </Col>
                    <Form.Group as={Col} xs={10} controlId="registrationPhoneNumber" className='position-relative'>
                        <Form.Control
                            type="text"
                            onChange={(e) => (props.handleSetPhoneNumber as ((args: string) => void))(e.target.value)}
                            placeholder="Phone Number"
                            value={props.phoneNumber} />
                    </Form.Group>
                </Row>
                :
                null
            }
        </React.Fragment>);
}

export default BasicAccountInformationComponent;