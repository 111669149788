import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FadeInSection from './FadeInSection';


function FeaturesSectionComponent() {

    return (
        <FadeInSection>
            <Row id="features" className="scrollable">
                <Container id="featuresContainer">
                    <Row>
                        <Col>
                            <div id="divFeaturesHeader">FEATURES</div>
                            <div id="divFeaturesTarget">
                                Target Specific Risks <br />
                                Matching Carrier Appetite
                            </div>
                            <div id="divFeatureOurPlatform">
                                <p>
                                    Our platform empowers your agency to reach the prospects you want to reach. Agency Pipe will help you manage and automate your marketing processes, streamline workflows, and
                                    generate a pipeline of prospects matching your criteria
                                </p>
                                <p>Focus on high hit ratio prospects based on known carrier appetitie</p>
                            </div>
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <Col xs={3}>
                                        <img className="featuresIcon" src="/img/property_icon.svg"></img>
                                    </Col>
                                    <Col>
                                        <h3>
                                            <p>Property Data</p>
                                        </h3>
                                        <p>Property Data Choose from 50+ criteria including Year Built, Square Footage, and Permit Data to identify prospects</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={3}>
                                        <img className="featuresIcon" src="/img/hazard_icon.svg"></img>
                                    </Col>
                                    <Col>
                                        <h3>
                                            <p>Hazard Data</p>
                                        </h3>
                                        <p>Pre-Quality risks utilizing our proprietary hazard data matching including Flood Zone, Distance To Coast, and Wind Pool zones</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={3}>
                                        <img className="featuresIcon" src="/img/integrations_automations.svg"></img>
                                    </Col>
                                    <Col>
                                        <h3>
                                            <p>Integrations & Automation</p>
                                        </h3>
                                        <p>Quickly connect and integrate your Agency tools, including CRM's, Mailers, Dialers, and Carrier Rating Portals</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                </Container>
            </Row>
        </FadeInSection>)
}
export default FeaturesSectionComponent;