import React, { ChangeEvent, useEffect, useState, ReactNode } from 'react';
//import { Offcanvas } from 'react-bootstrap';
import { Container, Row, Col, Modal, Spinner, Nav, NavDropdown } from 'react-bootstrap';
import './css/MainAppComponent.css';
import Card from 'react-bootstrap/Card';
import FiltersComponenent from './FiltersComponent';
import GoogleMap from '../maps/GoogleMap';
import './css/lnavbar.css';
import SearchResultResultComponent from './SeachResultComponent';
import { Button } from '@mui/material';
import { SearchResultResponseType } from '../../types/SearchResultTypes';
import { ApplicationContextType } from '../../types/LoggedInTypes';
import { Interfaces as AgencyPipeInterfaces } from 'agencypipe_commonlib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import CampaignComponent from './CampaignComponent';
import AccountComponent from '../account/AccountTabControllerComponent';
import { Gear } from 'react-bootstrap-icons';
import './css/mainApp.scss';

type MainAppType = {
  applicationContext: ApplicationContextType;
};

function MainApp(props: MainAppType) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataSummaryVars, setDataSummaryVars] = useState({
    totalNewConstructionCount: 0,
    totalCount: 0,
    totalRoofPermitCount: 0,
    totalGoFloodCount: 0,
    totalVaveCount: 0
  });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [showFilters, setShowFilters] = useState(true);
  const [costPerSqFoot, setCostPerSqFoot] = useState(350);
  const [showLoaindingDisplay, setShowLoaindingDisplay] = useState(true);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [dataSets, setDataSets] = useState({
    states: [],
    counties: {}
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState({
    locationFilter: null,
    hazardFilter: null,
    copeFilter: null,
    form5500Filter: null,
    permitFilter: null
  });

  const [searchResults, setSearchResults] = useState<SearchResultResponseType | null>(null);
  const [filteredSearchResults, setFilteredSearchResults] = useState<SearchResultResponseType | null>(null);
  const [filteredBy, setFilteredBy] = useState<string | null>(null);
  const [selectedSearchResult, setSelectedSearchResult] = useState<AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [modalData, setModalData] = useState<ReactNode>(<></>);
  const [showModal, setShowModal] = useState(false);

  const onToggleFilterShow = () => {
    setShowFilters(!showFilters);
  };
  const [showAccount, setShowAccount] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const userToken = props.applicationContext.token || '';
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set('Content-Type', 'application/json');
      requestHeaders.set('Authorization', userToken);
      // const me = this;
      const response = await fetch('/api/datasets', {
        method: 'GET',
        headers: requestHeaders
      });

      if (response.status == 200) {
        const dataSets = await response.json();
        setDataSets(dataSets);
      } else {
        props.applicationContext.onInvalidSession();
      }
    };

    if (!props?.applicationContext.user || !props?.applicationContext.token) {
      props.applicationContext.onInvalidSession();
      return;
    } else fetchData();
  }, []);

  const onFilterChange = (updatedFilterItem: object) => {
    setFilters((filters) => ({
      ...filters,
      ...updatedFilterItem
    }));
  };

  const handleOnMapBoundsChange = (bounds: google.maps.LatLngBounds) => {
    const updatedLocationFilter = filters.locationFilter || { bounds: bounds?.toJSON() };
    updatedLocationFilter.bounds = bounds?.toJSON();
    onFilterChange({
      locationFilter: updatedLocationFilter
    });
  };
  const handleSetSelectedPlace = (place: google.maps.places.PlaceResult) => {
    setSelectedPlace(place);
  };

  function fiilterByPermit(filter: string | null) {
    if (filter == null) {
      setFilteredBy(filter);
      setFilteredSearchResults(searchResults);
      return;
    }
    if (filteredBy == filter) {
      fiilterByPermit(null);
      return;
    }
    setFilteredBy(filter);
    const newFilterResponse = {
      response: {
        results: []
      }
    } as SearchResultResponseType;
    searchResults?.response?.results?.forEach((assessorResult) => {
      if (assessorResult.permits[filter]?.count > 0) newFilterResponse?.response?.results?.push(assessorResult);
    });
    setFilteredSearchResults(newFilterResponse);
  }

  function handleSearchResultsReceived(searchResultResponse: SearchResultResponseType) {
    setSearchResults(searchResultResponse);
    setFilteredSearchResults(searchResultResponse);
    setShowFilters(false);
    let totalRoofPermitCount = 0;
    let totalCount = 0;
    let totalNewConstructionCount = 0;
    let totalGoFloodCount = 0;
    let totalVaveCount = 0;
    searchResultResponse?.response?.results?.forEach((assessorResult) => {
      totalRoofPermitCount += assessorResult?.permits['ROOFING']?.count || 0;
      totalNewConstructionCount += assessorResult?.permits['NEW CONSTRUCTION']?.count || 0;

      //todo: improve this
      totalGoFloodCount += assessorResult.areabuilding * costPerSqFoot <= 1899999 ? 1 : 0;
      totalVaveCount += assessorResult.areabuilding * costPerSqFoot <= 1500000 && Number(assessorResult.yearbuilt) >= 1985 ? 1 : 0;
    });
    totalCount = searchResultResponse?.response?.results?.length || 0;
    setDataSummaryVars({
      totalNewConstructionCount: totalNewConstructionCount,
      totalCount: totalCount,
      totalRoofPermitCount: totalRoofPermitCount,
      totalGoFloodCount: totalGoFloodCount,
      totalVaveCount: totalVaveCount
    });
  }

  async function convertResponseToSearchResultResponse(searchResultResponse: Response): Promise<SearchResultResponseType> {
    const searchResult = new SearchResultResponseType();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const jsonResults = await searchResultResponse.json();
    const returnSearchResultResponseObject = Object.assign(searchResult, jsonResults);
    return returnSearchResultResponseObject;
  }

  async function onSearch(bounds: google.maps.LatLngBounds | null = null) {
    if (!props.applicationContext.token) {
      props.applicationContext.onInvalidSession();
      return;
    }
    const userToken = props.applicationContext.token;
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Authorization', userToken);
    if (bounds) {
      const updatedLocationFilter = filters.locationFilter || { bounds: bounds?.toJSON() };
      updatedLocationFilter.bounds = bounds?.toJSON();
      // @ts-expect-error type is defined as null, someone in the future fix
      filters.locationFilter = updatedLocationFilter;
    }
    setShowLoaindingDisplay(false);
    const response = await fetch('/api/search', {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(filters)
    });
    setShowLoaindingDisplay(true);
    if (response.status === 200) {
      const searchResult = await convertResponseToSearchResultResponse(response);
      handleSearchResultsReceived(searchResult);
      // props.setSearchResults(await response.json());
    } else {
      alert('Unknown Error Occured');
      props.applicationContext.onInvalidSession();
    }
  }
  function ModalFnc(props: { children: ReactNode }) {
    return (
      <Modal
        size="xl"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function filterByCompany(company: string) {
    if (filteredBy == company) {
      fiilterByPermit(null);
      return;
    }
    setFilteredBy(company);

    const newFilterResponse = {
      response: {
        results: []
      }
    } as SearchResultResponseType;

    searchResults?.response?.results?.forEach((assessorResult) => {
      switch (company) {
        case 'GoFlood':
          if (assessorResult.areabuilding * costPerSqFoot <= 1899999) newFilterResponse.response?.results?.push(assessorResult);
          break;
        case 'Vave':
          if (assessorResult.areabuilding * costPerSqFoot <= 1500000 && Number(assessorResult.yearbuilt) >= 1985) newFilterResponse.response?.results?.push(assessorResult);
          break;
      }
    });
    setFilteredSearchResults(newFilterResponse);
  }

  const handleSetShowCampaings = (show: boolean) => {
    setShowCampaigns(show);
  };

  const handleUpdateSortedResults = (results: Array<AgencyPipeInterfaces.AssessorTypes.IAssessorDataType>) => {
    const newFilterResponse = {
      response: {
        results: results
      }
    } as SearchResultResponseType;
    setFilteredSearchResults(newFilterResponse);
  };

  const handleCostPerSqFootChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCostPerSqFoot(Number(e.currentTarget.value));
  };
  const handleChangeShowProfileState = (show: boolean) => {
    setShowAccount(show);
  };

  return (
    <Container id="ApplicationContainer" fluid className="d-flex flex-column vh-100">
      <ModalFnc>{modalData}</ModalFnc>
      <div id="mainApp" className={`${showAccount ? 'd-none' : ''} d-flex flex-column vh-100`}>
        <Row id="navigationRow" className="addShadowOnRow flex-shrink-0">
          <Col id="navigationRowLogo" xs={11} className="d-flex align-items-center">
            <img src="/img/agency_pipe_logo.jpg" width="180" height="70" className="d-inline-block align-top float-start" alt="Agency Pipe" />
            <div id="searchContextWrapper" className="d-flex align-items-center ms-4">
              <input id="searchTextBox" name="search" data-lpignore="true" type="text" placeholder="Search" className="align-middle search hide-lastpass" ref={inputRef}></input>
              <i
                id="addressSearchButton"
                className="fa fa-search"
                aria-hidden="true"
                onClick={() => {
                  onSearch(null);
                }}
              ></i>

              <Button
                className="align-middle ms-3"
                color="primary"
                variant="contained"
                onClick={() => {
                  onSearch(null);
                }}
                size="small"
              >
                Search
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={showLoaindingDisplay} className="ms-1" />
              </Button>
              <span id="filterButtonShow" onClick={onToggleFilterShow}>
                <i className="fa fa-filter" aria-hidden="true"></i> Filters
              </span>
            </div>
          </Col>

          <Col xs={1} className="d-flex align-items-center">
            <Nav>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title={
                  <div style={{ display: 'inline-block', fontSize: 'small' }}>
                    <Gear className="me-1" size={16}></Gear>
                    <span>Settings</span>{' '}
                  </div>
                }
              >
                <NavDropdown.Item>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeShowProfileState(!showAccount);
                    }}
                    className="dropdown-toggle d-flex align-items-center"
                    id="navbarDropdownMenuAvatar"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i>
                      <FontAwesomeIcon icon={faUser} style={{ color: '#000000' }}></FontAwesomeIcon> Profile
                    </i>
                  </a>
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <span>Sign Out</span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Col>
        </Row>
        <Row id="DataSummaryContainer"></Row>

        <Row id="filterMapResultsRow" className="flex-grow-1 overflow-auto">
          <Col id="mapCol" sm={12} lg={7}>
            <Container fluid className="d-flex flex-column h-100">
              <Row className="flex-shrink-0">
                <Container>
                  <Row>
                    <Col>
                      <Card
                        className={`float-start dataSummaryCard ${filteredBy == null ? 'square border border-success' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          fiilterByPermit(null);
                        }}
                      >
                        <Card.Title className="card-title-text">Total Results</Card.Title>
                        <Card.Body>
                          <div className="dataSummaryResultTextWrapper">
                            <span>{dataSummaryVars.totalCount}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        className={`float-start dataSummaryCard ${filteredBy == 'NEW CONSTRUCTION' ? 'square border border-success' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          fiilterByPermit('NEW CONSTRUCTION');
                        }}
                      >
                        <Card.Title className="card-title-text">New Build</Card.Title>
                        <Card.Body>
                          <div className="dataSummaryResultTextWrapper">
                            <span>{dataSummaryVars.totalNewConstructionCount}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        className={`float-start dataSummaryCard ${filteredBy == 'ROOFING' ? 'square border border-success' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          fiilterByPermit('ROOFING');
                        }}
                      >
                        <Card.Title className="card-title-text">Roof Permits</Card.Title>
                        <Card.Body>
                          <div className="dataSummaryResultTextWrapper">
                            <span>{dataSummaryVars.totalRoofPermitCount}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        className={`float-start dataSummaryCard ${filteredBy == 'GoFlood' ? 'square border border-success' : ''}`}
                        id="goFloodCard"
                        onClick={(e) => {
                          e.preventDefault();
                          filterByCompany('GoFlood');
                        }}
                      >
                        <Card.Title className="card-title-img">
                          <img className="responsive" src="/img/gobuyflood_logo_nf1zom.jpg"></img>
                        </Card.Title>
                        <Card.Body>
                          <div className="dataSummaryResultTextWrapper">
                            <span>{dataSummaryVars.totalGoFloodCount}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        className={`float-start dataSummaryCard ${filteredBy == 'Vave' ? 'square border border-success' : ''}`}
                        id="vaveCard"
                        onClick={(e) => {
                          e.preventDefault();
                          filterByCompany('Vave');
                        }}
                      >
                        <Card.Title className="card-title-img">
                          <img className="responsive" src="/img/vave-logo.png"></img>
                        </Card.Title>
                        <Card.Body>
                          <div className="dataSummaryResultTextWrapper">
                            <span>{dataSummaryVars.totalVaveCount}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Row>
              <Row className="flex-grow-1">
                <GoogleMap
                  onSelectedPlace={handleSetSelectedPlace}
                  apiKey="AIzaSyD16v8ec5hcx77ZCQIQadZKJbmWL9755wc"
                  onSearch={onSearch}
                  inputRef={inputRef}
                  userToken={props.applicationContext.token}
                  searchResults={searchResults}
                  selectedSearchResult={selectedSearchResult}
                  onBoundschange={handleOnMapBoundsChange}
                ></GoogleMap>
              </Row>
            </Container>
          </Col>
          <Col id="resultsCol" sm={12} lg={5}>
            <Container id="resultsColContainer" fluid>
              <Row id="resultsColContainerRow">
                <SearchResultResultComponent
                  setShowCampaigns={handleSetShowCampaings}
                  setMainModalData={(data: ReactNode) => setModalData(data)}
                  setMainShowModal={setShowModal}
                  selectedPlace={selectedPlace}
                  applicationContext={props.applicationContext}
                  staticMapApiKey="AIzaSyD16v8ec5hcx77ZCQIQadZKJbmWL9755wc"
                  token={props.applicationContext.token}
                  searchResults={filteredSearchResults}
                  handleCardClick={(item) => {
                    if (item != selectedSearchResult) {
                      selectedSearchResult?.marker?.setIcon(null);
                    }
                    setSelectedSearchResult(item);
                  }}
                  updateSortedResults={handleUpdateSortedResults}
                ></SearchResultResultComponent>
              </Row>
            </Container>
          </Col>
          <Col id="filterCol" xs={5} className={`position-absolute  ${showFilters ? 'show-filters' : 'hide-filters'}`}>
            <FiltersComponenent
              handleCostPerSqFootChange={(e) => handleCostPerSqFootChange(e)}
              costPerSqFoot={costPerSqFoot}
              toggleFilterShow={onToggleFilterShow}
              dataSets={dataSets}
              handleFilterChange={onFilterChange}
            ></FiltersComponenent>
          </Col>
        </Row>
      </div>
      <div id="accountTab" className={`${showAccount ? '' : 'd-none'} d-flex flex-column vh-100`}>
        <AccountComponent applicationContext={props.applicationContext} setShowState={handleChangeShowProfileState} />
      </div>

      <Modal id="campaignModal" show={showCampaigns} onHide={() => setShowCampaigns(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Manage Lists</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CampaignComponent applicationContext={props.applicationContext}></CampaignComponent>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default MainApp;
