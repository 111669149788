/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { Interfaces as AgencyPipeInterfaces } from "agencypipe_commonlib";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBCheckbox,
} from 'mdb-react-ui-kit';
import PassReset from './ForgotPass';
import "./css/SignUpSignIn.css"
import PasswordAndConfirmationComponent from '../account/PasswordAndConfirmationComponent';
import BasicAccountInformationComponent from '../account/BasicAccountInformationComponent';



export enum TabViewEnum {
    signin = "signInTab",
    register = "registerTab"
}
type SignUpSignInProps = {

    defaultView: TabViewEnum,
    handleOnCloseClick: () => void
    onSignInSuccess: (user: AgencyPipeInterfaces.UserTypes.AgencyPipeUser, token: string) => void
}




function SignUpSignIn(props: SignUpSignInProps) {

    const [signInPasswordIcon, setSignInPasswordIcon] = useState(eyeOff);
    const [signInPasswordType, setSignInPasswordType] = useState('password');




    const handleToggleSignInPassword = () => {
        if (signInPasswordType === 'password') {
            setSignInPasswordIcon(eye);
            setSignInPasswordType('text')
        } else {
            setSignInPasswordIcon(eyeOff)
            setSignInPasswordType('password')
        }
    }

    const [fillActive, setFillActive] = useState(props.defaultView);
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });
    const [registrationName, setRegistrationName] = useState('');
    const [registrationEmail, setRegistrationEmail] = useState('');
    const [registrationPassword, setRegistrationPassword] = useState('');


    const [registrationCompanyName, setRegistrationCompanyName] = useState('');
    const [forgotVis, setForgotVis] = useState(false);

    const forgotPassClick = () => {
        setForgotVis(true);
    };

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {

            return;
        }
        else
            handleRegistration();


    };


    function handleLogin() {
        const fetchData = async () => {
            const response = await fetch('/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });

            if (response.status == 200) {
                const data = await response.json();
                if (data?.response.status == "success" && data?.response?.token) {
                    props.onSignInSuccess(data?.response?.user, data?.response?.token);
                } else {
                    alert('Invalid Email Address or Password');
                }
            }
            else if (response.status == 401)
                alert('Invalid Email Address or Password');
            else
                alert("An Unknown Error Occured");
        };
        fetchData();
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleRegistration() {

        const newUserRegistration = {
            profile: {
                name: registrationName,
                company: {
                    name: registrationCompanyName
                }
            },
            email: registrationEmail,
            password: registrationPassword,

        };

        const fetchData = async () => {
            const response = await fetch('/api/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newUserRegistration),
            });

            const data = await response.json();
            if (data?.response?.status === "success") {
                alert('Registration Success. Please Check Your Email To Confirm');
                setFillActive(TabViewEnum.signin);
            } else {
                alert('An Unknown Error Occured. Please Contact Support');
            }

        };
        fetchData();
    }

    const handleSetRegistrationPassword = (registrationPassword: string) => {
        setRegistrationPassword(registrationPassword);
    }

    const handleSetRegistrationEmailAddress = (emailAddress: string) => {
        setRegistrationEmail(emailAddress);
    }
    const handleSetRegistrationFullName = (fullName: string) => {
        setRegistrationName(fullName);
    }
    const handleSetRegistrationCompanyName = (companyName: string) => {
        setRegistrationCompanyName(companyName);
    }


    const handleFillClick = (value: TabViewEnum) => {
        if (value === fillActive) {
            return;
        }

        setFillActive(value);
    };

    return (
        <>
            <MDBTabs pills fill className='mb-3'>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleFillClick(TabViewEnum.signin)} active={fillActive === TabViewEnum.signin}>
                        Sign In
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => { handleFillClick(TabViewEnum.register) }} active={fillActive === TabViewEnum.register}>
                        Register
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>
            <MDBTabsContent >
                <MDBTabsPane open={fillActive === 'signInTab'}>


                    <Row className='mb-4'>
                        <Col xs={1}>
                            <i className="fa-solid fa-user"></i>
                        </Col>
                        {/* For Some Reason, wrapping in Col is throwing Error https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications */}
                        <input id='signInEmail' className='signInInput' type='email' placeholder='Email Address' onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}></input>

                    </Row>


                    <Row>
                        <Col xs={1}>
                            <i className="fa-solid fa-lock"></i>
                        </Col>

                        <input id='signInPassword' className='signInInput' placeholder='Password' type={signInPasswordType}
                            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); handleLogin();
                                }
                            }} />

                        <Col xs={1}>
                            <span className="flex justify-around items-center" onClick={handleToggleSignInPassword}>
                                <Icon className="passwordToggleIcon absolute mr-10" icon={signInPasswordIcon} size={25} />
                            </span>
                        </Col>

                    </Row>
                    <Row className='mt-1'>
                        <Col xs={{ span: 5, offset: 1 }}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
                        </Col>
                        <Col>
                            <Button id="forgotPasswordBtn" color='primary' onClick={forgotPassClick}> Forgot password?</Button>
                        </Col>
                    </Row>

                    <Container >
                        <Row className='mt-4'>

                            <Col>
                                <Typography align='center'>
                                    <Button color="primary" variant="contained" onClick={(e) => { e.preventDefault(); props.handleOnCloseClick(); }}>
                                        Cancel
                                    </Button>
                                </Typography>
                            </Col>
                            <Col>
                                <Typography align='center'>
                                    <Button color="primary" variant="contained" onClick={(e) => { e.preventDefault(); handleLogin(); }}>
                                        Sign in
                                    </Button>
                                </Typography>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                        </Row>
                    </Container>

                    {forgotVis && <PassReset />}

                </MDBTabsPane>
                <MDBTabsPane open={fillActive === 'registerTab'}>
                    <Form noValidate validated={validated} onSubmit={(e) => { handleSubmit(e) }}>
                        <BasicAccountInformationComponent handleSetEmail={handleSetRegistrationEmailAddress}
                            handleSetFullName={handleSetRegistrationFullName}
                            handleSetCompanyName={handleSetRegistrationCompanyName}>
                        </BasicAccountInformationComponent>
                        <PasswordAndConfirmationComponent registrationPassword={registrationPassword}
                            handleSetRegistrationPassword={handleSetRegistrationPassword}>
                        </PasswordAndConfirmationComponent>
                        <Container>
                            <div className='d-flex justify-content-center mb-4'>
                                <MDBCheckbox
                                    required
                                    name='flexCheck'
                                    id='chkReadTOS'
                                    label='I have read and agree to the terms' />
                            </div>
                            <Row>

                                <Col>
                                    <Typography align='center'>
                                        <Button color="primary" variant="contained" onClick={(e) => { e.preventDefault(); props.handleOnCloseClick(); }}>
                                            Cancel
                                        </Button>
                                    </Typography>
                                </Col>
                                <Col>
                                    <Typography align='center'>
                                        <Button color="primary" variant="contained" type='submit' >
                                            Sign Up
                                        </Button>
                                    </Typography>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </MDBTabsPane>
            </MDBTabsContent >
        </>
    );
}

export default SignUpSignIn;