import React, { useState } from 'react';
import { ApplicationContextType } from "../../types/LoggedInTypes";
import './css/AccountProfileComponentStyleSheet.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import BasicAccountInformationComponent from './BasicAccountInformationComponent';
import LocationProfileComponent from './LocationProfileComponent';

type AccountProfileComponentPropType = {
    applicationContext: ApplicationContextType
}
const AccountProfileComponent = (props: AccountProfileComponentPropType) => {

    const user = props.applicationContext.user;
    const userAddress = user?.profile.address;

    const [userFullName, setUserFullName] = useState(user?.profile.name);
    const userEmailAddress = user?.email;
    const [userPhoneNumber, setUserPhoneNumber] = useState(user?.profile.phoneNumber)
    const [userCompanyName, setUserCompanyName] = useState(user?.profile.company.name);

    if (!props.applicationContext.user || !props.applicationContext.token) {
        props.applicationContext.onInvalidSession();
        return <></>;
    }

    const [userProfileValidated, setUserProfileValidated] = useState(false);
    const handleUpdateUserProfile = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        setUserProfileValidated(true);
        event.preventDefault();
        event.stopPropagation();
        event.stopPropagation();
        if (form.checkValidity() === false)
            return;
    }
    const handleSetUserFullName = (fullName: string) => {
        setUserFullName(fullName);
    }

    const handleSetUserCompanyName = (companyName: string) => {
        setUserCompanyName(companyName);
    }
    const handleSetUserPhoneNumber = (phoneNumber: string) => {
        setUserPhoneNumber(phoneNumber);
    }

    const handleProfileUpdate: () => void = () =>{
        const fetchData = async () => {
            const credentials = {
                userFullName,
                userPhoneNumber,
                userAddress
            }
            const response = await fetch('/auth/update/profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });
    
            if (response.status == 200) {
                const data = await response.json();
                if (data?.response.status == "success" && data?.response?.token) {
                    alert('Succesful update')
                } else {
                    alert('200 error');
                }
            }
            else if (response.status == 401)
                alert('401 error');
            else
                alert("Other error");
        };
        fetchData();

        return 0;
    }

    return (
        <Container fluid>
            <Row>
                <Col xs={6}>
                    <Form noValidate validated={userProfileValidated} onSubmit={(e) => { handleUpdateUserProfile(e) }}>
                        <BasicAccountInformationComponent
                            handleSetFullName={handleSetUserFullName}
                            handleSetCompanyName={handleSetUserCompanyName}
                            handleSetPhoneNumber={handleSetUserPhoneNumber}
                            fullName={userFullName}
                            emailAddress={userEmailAddress}
                            companyName={userCompanyName}
                            phoneNumber={userPhoneNumber}

                        >

                        </BasicAccountInformationComponent>
                        <LocationProfileComponent userAddress={userAddress}></LocationProfileComponent>
                    </Form>
                    <Button onClick={handleProfileUpdate}> Update Profile </Button>
                </Col>
                <Col xs={6}>
                    <div className="card mb-4">
                        <div className="card-header">Change Password</div>
                        <div className="card-body">
                            <form>
                                {/*<!-- Form Group (current password)--> */}
                                <div className="mb-3">
                                    <label className="small mb-1" htmlFor="currentPassword">Current Password</label>
                                    <input className="form-control" id="currentPassword" type="password" placeholder="Enter current password" />
                                </div>
                                {/*<!-- Form Group (new password)--> */}
                                <div className="mb-3">
                                    <label className="small mb-1" htmlFor="newPassword">New Password</label>
                                    <input className="form-control" id="newPassword" type="password" placeholder="Enter new password" />
                                </div>
                                {/*<!-- Form Group (confirm password)--> */}
                                <div className="mb-3">
                                    <label className="small mb-1" htmlFor="confirmPassword">Confirm Password</label>
                                    <input className="form-control" id="confirmPassword" type="password" placeholder="Confirm new password" />
                                </div>
                                <button className="btn btn-primary" type="button">Save</button>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >



    )
}

export default AccountProfileComponent;