/* eslint-disable require-jsdoc */
/* eslint-disable no-unused-vars */
import React, { RefObject, useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { SearchResultResponseType } from '../../types/SearchResultTypes';
import { Interfaces as AgencyPipeInterfaces } from 'agencypipe_commonlib';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useMap } from './MapContext';

const containerStyle = {
  width: '100%',
  minHeight: '100%'
};

export type GoogleMapSettingsType = {
  zoom: number;
  center: {
    lat: number;
    lng: number;
  };
};

type GoogleMapComponentPropTypes = {
  userToken: string | null;
  searchResults: SearchResultResponseType | null;
  selectedSearchResult: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null;
  onBoundschange: (bounds: google.maps.LatLngBounds) => void;
  inputRef: RefObject<HTMLInputElement>;
  onSearch: (bounds: google.maps.LatLngBounds | null) => void;
  apiKey: string;
  onSelectedPlace: (place: google.maps.places.PlaceResult) => void;
};

function GoogleMapComponent(props: GoogleMapComponentPropTypes) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: props.apiKey,
    libraries: ['maps', 'places']
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [googleMapSettings, setGooglMapSettings] = useState<GoogleMapSettingsType>({
    zoom: 4,
    center: {
      lat: 35.95078159971039,
      lng: -93.30989249999999
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [searchResultMarkers, setSearchResultMarkers] = useState<Array<google.maps.Marker> | null>(null);
  const [markerCluster, setMarkerCluster] = useState<MarkerClusterer | null>(null);
  const { mapCenter, setMapCenter, markers, mapZoom, setMapZoom } = useMap();
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (!isLoaded) return;

    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    if (google?.maps?.places && props.inputRef?.current && map) {
      const autocomplete = new google.maps.places.Autocomplete(props.inputRef.current, options);
      autocomplete.addListener('place_changed', function () {
        const place = autocomplete.getPlace();
        props.onSelectedPlace(place);
        if (place?.geometry?.location)
          setMapCenter({ lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng() });
        setMapZoom(18);
        const boundsChangeListener = map.addListener('bounds_changed', () => {
          //setTimeout(() => {
          props.onSearch(map.getBounds() as google.maps.LatLngBounds);

          boundsChangeListener.remove();
          //}, 250)
        });
      });
    }
  }, [map]);

  useEffect(() => {
    searchResultMarkers?.forEach((resultMarker) => {
      resultMarker.setMap(null);
    });

    const newMarkerArray = props.searchResults?.response?.results?.map((result): google.maps.Marker => {
      const newMapMarker = new google.maps.Marker({
        map: map,
        position: new google.maps.LatLng({ lng: result.lng, lat: result.lat })
      });
      result.marker = newMapMarker;
      return newMapMarker;
    });
    setSearchResultMarkers(newMarkerArray || null);
    if (newMarkerArray && markerCluster) {
      markerCluster.clearMarkers();
      // markerCluster.setMap(map);
      markerCluster.addMarkers(newMarkerArray);
      //new MarkerClusterer({ markers: newMarkerArray, map });
    }
  }, [props.searchResults]);

  useEffect(() => {
    if (!props.selectedSearchResult)
      return;
    const lat = Number(props.selectedSearchResult?.marker?.getPosition()?.lat());
    const lng = Number(props.selectedSearchResult?.marker?.getPosition()?.lng());
    props.selectedSearchResult?.marker?.setIcon({
      url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
    });

    const updatedGoogleMapSettings: GoogleMapSettingsType = {
      zoom: 18,
      center: {
        lat: lat,
        lng: lng
      }
    };
    //setGooglMapSettings(updatedGoogleMapSettings);
    setMapZoom(updatedGoogleMapSettings.zoom);
    setMapCenter(updatedGoogleMapSettings.center);
  }, [props.selectedSearchResult]);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map);
    mapRef.current = map
    //setMapCenter(googleMapSettings.center);

    const srsConversion = (latLng: google.maps.LatLng | null | undefined) => {
      if (latLng == null || latLng == undefined) return;
      if (Math.abs(latLng.lng()) > 180 || Math.abs(latLng.lat()) > 90) {
        return;
      }

      const num = latLng.lng() * 0.017453292519943295;
      const x = 6378137.0 * num;
      const a = latLng.lat() * 0.017453292519943295;

      return { lng: x, lat: 3189068.5 * Math.log((1.0 + Math.sin(a)) / (1.0 - Math.sin(a))) };
    };
    const wmtsTileLayer = new window.google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        const proj = map.getProjection();
        const zfactor = Math.pow(2, zoom);
        // get Long Lat coordinates
        const top = proj?.fromPointToLatLng(new window.google.maps.Point((coord.x * 256) / zfactor, (coord.y * 256) / zfactor));
        const bot = proj?.fromPointToLatLng(new window.google.maps.Point(((coord.x + 1) * 256) / zfactor, ((coord.y + 1) * 256) / zfactor));

        const topConverted = srsConversion(top);
        const botConverted = srsConversion(bot);

        // create the Bounding box string
        const bbox = topConverted?.lng + ',' + botConverted?.lat + ',' + botConverted?.lng + ',' + topConverted?.lat;

        // base WMS URL
        let url = '/geoserver/xraes/wms?service=WMS';
        url += '&REQUEST=GetMap'; // WMS operation
        url += '&SERVICE=WMS'; // WMS service
        url += '&VERSION=1.1.1'; // WMS version
        url += '&LAYERS=' + 'xraes%3Afl_flood'; // WMS layers
        url += '&FORMAT=image/png'; // WMS format
        url += '&TRANSPARENT=TRUE';
        url += '&SRS=EPSG:3857'; // set WGS84
        url += '&BBOX=' + bbox; // set bounding box
        url += '&WIDTH=256'; // tile size in google
        url += '&HEIGHT=256';
        url += `&token=${props.userToken}`;

        return url;
      },
      tileSize: new google.maps.Size(256, 256),
      name: 'Flood Layer',
      opacity: 0.5
    });

    map.overlayMapTypes.push(wmtsTileLayer);
    setMarkerCluster(new MarkerClusterer({ map: map }));
  }, []);

  const handleOnBoundsChange = () => {
    props.onBoundschange(map?.getBounds() as google.maps.LatLngBounds);
  };
  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const options = !isLoaded
    ? {}
    : {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
        position: google?.maps?.ControlPosition?.TOP_CENTER
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google?.maps?.ControlPosition?.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google?.maps?.ControlPosition?.RIGHT_BOTTOM
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_BOTTOM
      }
    };
  return isLoaded ? (
    <GoogleMap
      options={options}
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={mapCenter}
      zoom={mapZoom}
      onBoundsChanged={handleOnBoundsChange}>
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.center} onClick={marker.onClick} />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapComponent);
