import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import './css/Landing.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars


import NavBar from './NavBarComponent';
import HomeSectionComponent from './HomeSectionsComponent';
import FeaturesSectionComponent from './FeaturesSectionComponent';
import PricingSectionComponent from './PricingSectionComponent';
import FAQSectionComponent from './FAQSectionComponent';
import AboutUsSectionComponent from './AboutUsSectionComponent';
import SignUpSignIn, { TabViewEnum } from './SignUpSignIn';

import { Interfaces as AgencyPipeInterfaces } from "agencypipe_commonlib";


type LandingProps = {
  onSignInSuccess: (user: AgencyPipeInterfaces.UserTypes.AgencyPipeUser, token: string) => void
}

function Landing(props: LandingProps) {
  const [show, setShow] = useState(false);
  const [defaultView, setDefaultView] = useState(TabViewEnum.signin);


  const handleClose = () => setShow(false);
  const handleShow = (view: TabViewEnum) => { setDefaultView(view); setShow(true) };


  return (
    <Container id="landingContainer" fluid>
      <NavBar handleShow={handleShow}></NavBar>
      <HomeSectionComponent></HomeSectionComponent>
      <FeaturesSectionComponent></FeaturesSectionComponent>
      <PricingSectionComponent></PricingSectionComponent>
      <FAQSectionComponent></FAQSectionComponent>
      <AboutUsSectionComponent></AboutUsSectionComponent>

      <Modal show={show} onHide={handleClose} >

        <Modal.Body><SignUpSignIn defaultView={defaultView} handleOnCloseClick={handleClose} onSignInSuccess={props.onSignInSuccess}></SignUpSignIn></Modal.Body>

      </Modal>


      <Container className="footer fixed-bottom" >
        <Row>
          <Col>&copy; 2022 Company, Inc. All rights reserved.</Col>
        </Row>

        <Row>
          <Col>
            <a className="ms-1 me-1" href="mailto:info@agencypipe.com">
              info@agencypipe.com
            </a>
            <span className="ms-1 me-1">5900 Hiatus Rd., Tamarac, Fl. 33321</span>
            <span className="ms-1 me-1">+1 (561) 441-6464</span>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Landing;
