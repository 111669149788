import React, { useState } from 'react';
import { JsonToTable } from 'react-json-to-table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TableViewModelComponent = (props: TableViewModelPropType) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <a className='detailSelector' onClick={(e) => {
        e.preventDefault(); handleShow();
      }}>
        {props?.label ? props.label : 'Details'}

      </a>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{props?.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JsonToTable json={props.obj} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>);
};

type TableViewModelPropType = {
  obj: object
  label: string,
};
export default TableViewModelComponent;
