
import React from 'react'
import SignUpSignIn, { TabViewEnum } from '../landing/SignUpSignIn';
import { Interfaces as AgencyPipeInterfaces } from "agencypipe_commonlib";
import { Container, Modal } from 'react-bootstrap';
import './SignInSignUpComponentCss.css'




type SignInSignUpProps = {
  onSignInSuccess: (user: AgencyPipeInterfaces.UserTypes.AgencyPipeUser, token: string) => void
}

function SignInSignUpComponent(props: SignInSignUpProps) {
  const onCloseClick = () => {
  }

  return (
    <Container id="landingContainer" fluid>
      <Modal show={true} onHide={onCloseClick} >
        <SignUpSignIn defaultView={TabViewEnum.signin} handleOnCloseClick={onCloseClick} onSignInSuccess={props.onSignInSuccess}></SignUpSignIn>
      </Modal>
    </Container>
  )
}
export default SignInSignUpComponent;