import React, { useState } from 'react';
import { ApplicationContextType } from "../../types/LoggedInTypes";
import './css/AccountTabControllerComponentStyleSheet.css';

import { Tab, Tabs, Container, Row, Col } from 'react-bootstrap';
import AccountProfileComponent from './AccountProfileComponent';
//import AccountBillingComponent from './AccountBillingComponent';
//import AccountSecurityComponent from './AccountSecurityComponent';
//import AccountNotificationComponent from './AccountNotificationsComponent';
//import AccountIntegrationsComponent from './AccountIntegrationsComponent';

type AccountTabControllerComponentPropType = {
    applicationContext: ApplicationContextType,
    setShowState: (show: boolean) => void
}
const AccountTabControllerComponent = (props: AccountTabControllerComponentPropType) => {
    const [key, setKey] = useState<string>('profile');

    if (!props.applicationContext.user || !props.applicationContext.token) {
        props.applicationContext.onInvalidSession();
        return <></>;
    }

    const navigateBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        props.setShowState(false);
    }


    return (
        <Container className='mt-5 hideMeContainer'>
            <Row>
                <Col xs={11}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k as string)}
                        className="mb-3"
                        fill>
                        <Tab eventKey="profile" title="Profile">
                            <hr className="mt-0 mb-4" />
                            <AccountProfileComponent applicationContext={props.applicationContext}></AccountProfileComponent>
                        </Tab>
                        {/*  <Tab eventKey="billing" title="Billing">
                            <hr className="mt-0 mb-4" />
                            <AccountBillingComponent applicationContext={props.applicationContext}></AccountBillingComponent>
                        </Tab>

                        <Tab eventKey="security" title="Security">
                            <hr className="mt-0 mb-4" />
                            <AccountSecurityComponent applicationContext={props.applicationContext}></AccountSecurityComponent>
                        </Tab>
                        <Tab eventKey="notification" title="Notification">
                            <hr className="mt-0 mb-4" />
                            <AccountNotificationComponent applicationContext={props.applicationContext}></AccountNotificationComponent>
                        </Tab>
                      
                        <Tab eventKey="integrations" title="Integrations">
                            <hr className="mt-0 mb-4" />
                            <AccountIntegrationsComponent applicationContext={props.applicationContext}></AccountIntegrationsComponent>
                        </Tab>
                          */}
                    </Tabs>

                </Col>
                <Col xs={1}><button type="button" className="btn-close" aria-label="Close" onClick={navigateBack}></button></Col>
            </Row>
        </Container>
    );
}


export default AccountTabControllerComponent;