
import React, { useEffect } from "react";
import { ApplicationContextType } from '../../types/LoggedInTypes';
import { Interfaces as AgencyPipeInterfaces } from "agencypipe_commonlib";
import { Container, Row, Col, ListGroup, Table, Badge } from "react-bootstrap";
import './css/CampaignComponentStyleSheet.css';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PifUploadComponent from "./PifUploadComponent";

export type CampaignCompoentPropType = {
    applicationContext: ApplicationContextType
}

const CampaignComponent = (props: CampaignCompoentPropType) => {
    const [campaigns, setCampaigns] = React.useState<Array<AgencyPipeInterfaces.CampaignTypes.ICampaign>>([]);
    const [leads, setLeads] = React.useState<Array<AgencyPipeInterfaces.LeadTypes.ILead>>([]);
    const [selectedCampaign, setSelectedCampaign] = React.useState<AgencyPipeInterfaces.CampaignTypes.ICampaign | null>(null);

    const [selectedItems, setSelectedItems] = React.useState<Array<AgencyPipeInterfaces.LeadTypes.ILead>>([]);
    const [selectAll, setSelectAll] = React.useState<boolean>(false);
    const [showImportLeads, setShowImportLeads] = React.useState(false)

    useEffect(() => {
        const initialize = async () => {
            const campaignResults = await fetch('/api/campaigns', {
                method: 'GET',
                headers: getHeaders()
            });
            const campaignsJson = await campaignResults.json();
            const campaignResponseResults = campaignsJson?.response?.result || [];

            setCampaigns(campaignResponseResults);
            if (campaignResponseResults.length > 0)
                getCampainLeads(campaignResponseResults[0]);
        }

        initialize();

    }, []);

    const getHeaders = () => {
        const userToken = props.applicationContext.token || '';
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', userToken);
        return requestHeaders;
    }

    const getCampainLeads = (campaign: AgencyPipeInterfaces.CampaignTypes.ICampaign) => {
        setSelectedCampaign(campaign);
        setSelectAll(false);
        setSelectedItems([]);
        const getLeads = async () => {
            const leadsResults = await fetch(`/api/campaigns/${campaign._id}/leads`, {
                method: 'GET',
                headers: getHeaders()
            });
            const leads = await leadsResults.json();
            setLeads(leads?.response?.result || []);
            console.log(leads);
        };

        getLeads();
    }


    const isSelectedItemChecked = (item: AgencyPipeInterfaces.LeadTypes.ILead) => {
        return selectedItems.indexOf(item) > -1;
    }

    const handleSelectAllItemsCheck = () => {
        const newState = !selectAll;
        setSelectAll(newState);
        if (newState)
            setSelectedItems(leads || []);
        else
            setSelectedItems([]);
    }

    const handleItemCheck = (item: AgencyPipeInterfaces.LeadTypes.ILead) => {
        let newState = [];
        if (isSelectedItemChecked(item))
            newState = selectedItems.filter((i) => i !== item);
        else
            newState = [...selectedItems, item];

        setSelectedItems(newState);

        setSelectAll(newState.length == leads?.length);
    }

    const buildLeadList = () => {
        return selectedItems.map((lead) => {
            return lead._id;
        });
    }
    const sendPostcards = async () => {
        const leadList = buildLeadList();
        const action = "sendPostCard";
        const campaignId = selectedCampaign?._id;
        const sendPostCardResults = await fetch(`/api/campaigns/${campaignId}/sendPostCards`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ leads: leadList, action })
        });

        if (sendPostCardResults.status == 200) {
            selectedItems.forEach((lead) => {
                lead.actions.push("sendPostCard");
            })
            console.log("Postcards Queued");
            if (selectedCampaign)
                getCampainLeads(selectedCampaign);
        }
    }

    const sendEmails = async () => {
        const leadList = buildLeadList();
        const action = "sendEmail";
        const campaignId = selectedCampaign?._id;
        const sendPostCardResults = await fetch(`/api/campaigns/${campaignId}/sendEmails`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ leads: leadList, action })
        });
        if (sendPostCardResults.status == 200) {
            selectedItems.forEach((lead) => {
                lead.actions.push("sendEmail");
            })
            console.log("Emails Queued");
            if (selectedCampaign)
                getCampainLeads(selectedCampaign);
        }
    }

    const importLeads = async () => {
        setShowImportLeads(true);
    }



    return (
        <Container fluid>
            <PifUploadComponent show={showImportLeads} setShowImportLeadsState={setShowImportLeads}></PifUploadComponent>
            <Row>
                <Col xs={{ offset: 2 }} >
                    <Container>
                        <Row>
                            <Col xs={{ span: 3 }}>
                                <Button onClick={sendPostcards}>Send Postcards</Button>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Button onClick={sendEmails}>Send Emails</Button>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Button>Export Leads</Button>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Button onClick={importLeads}>Import Leads</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={2}>
                    <ListGroup as="ol" numbered >
                        {
                            campaigns.map((campaign) => {
                                return <ListGroup.Item active={selectedCampaign == campaign} className="d-flex justify-content-between align-items-start" as="li" key={campaign._id} onClick={() => getCampainLeads(campaign)}>
                                    <div className="ms-1 me-auto">
                                        <div> {campaign.name} </div>
                                    </div>

                                    <Badge className="me-auto" bg="primary" pill>
                                        {campaign.leadsCount || 0}
                                    </Badge>
                                    <IconButton aria-label="delete" size="small"><DeleteIcon fontSize="inherit" /></IconButton>

                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </Col>
                <Col xs={10} id="tableLeadCol">

                    <Table id="tableLeadList" striped hover>
                        <thead>
                            <tr>
                                <th><input type="checkbox" checked={selectAll} onChange={(e) => { e.stopPropagation(); handleSelectAllItemsCheck(); }}></input></th>
                                <th>Name</th>
                                <th>Street</th>
                                <th>City</th>
                                <th>State</th>
                                <th>PostalCode</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {leads.map((lead) => {
                                const leadData = lead.data as AgencyPipeInterfaces.AssessorTypes.IAssessorDataType
                                return <tr key={lead._id} className="leadDataRow">
                                    <td><input type="checkbox" checked={isSelectedItemChecked(lead)} onChange={(e) => { e.stopPropagation(); handleItemCheck(lead); }}></input></td>
                                    <td>{leadData.deedowner1namefull}</td>
                                    <td>{leadData.propertyaddressfull}</td>
                                    <td>{leadData.propertyaddresscity}</td>
                                    <td>{leadData.propertyaddressstate}</td>
                                    <td>{leadData.propertyaddresszip}</td>
                                    <td>
                                        <Container>
                                            {lead.actions.map((action, index) => {
                                                if (action == "sendEmail")
                                                    return <IconButton key={`${lead._id}_${action}_${index}`} aria-label="delete" size="small"><EmailIcon fontSize="inherit" /></IconButton>
                                                else if (action == "sendPostCard")
                                                    return <IconButton key={`${lead._id}_${action}_${index}`} aria-label="delete" size="small">  <i className="fa-brands fa-usps"></i></IconButton>
                                            })}
                                        </Container>
                                    </td>
                                    <td>
                                        <IconButton aria-label="delete" size="small"><DeleteIcon fontSize="inherit" /></IconButton>
                                    </td>
                                </tr>
                            })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </Container>
    );
}

export default CampaignComponent;
