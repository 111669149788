/* eslint-disable max-len */
import React, { useEffect, useState, useMemo, createRef, ReactNode } from 'react';
import { Row, Col, Container, Spinner, Dropdown, DropdownButton } from 'react-bootstrap';
import './css/SearchResultComponent.css';
import { SearchResultResponseType } from '../../types/SearchResultTypes';
import { Interfaces as AgencyPipeInterfaces } from 'agencypipe_commonlib';
import { ApplicationContextType } from '../../types/LoggedInTypes';
import { Button } from '@mui/material';
import stringSimilarity from 'string-similarity';
import { Modal, ListGroup } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faComment, faEnvelope, faFileExport, faPhone, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faUsps } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import NotesComponent from './NotesComponent';

// import Dropdown from 'react-bootstrap/Dropdown';
// import GoogleMap from './Maps/GoogleMap';
// import {Nav, NavItem} from 'reactstrap';

import TableViewModelComponent from './TableViewModelComponent';
import { SingleValue } from 'react-select';


const formatNumber = (num: number, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  }); // You can specify a different locale if needed
  const formattedNumber = formatter.format(num);
  return formattedNumber;
};
const formatCurrency = (amount: number | null) => {
  // Format currency with specific options
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  if (!amount) return NaN;
  const formattedAmount = currencyFormatter.format(amount);
  return formattedAmount;
};
type GenerateIndicatorsProps = {
  item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType;
  userToken?: string | null;
};
const GenerateIndicators = (props: GenerateIndicatorsProps) => {
  const existNewConstruction = (props.item.permits['NEW CONSTRUCTION']?.count || 0) > 0;
  const existRoofingPermit = (props.item.permits['ROOFING']?.count || 0) > 0;
  return (
    <>
      <span className="newConstructionIndicator">{existNewConstruction ? <i className="fa-solid fa-hammer"></i> : <></>}</span>
      &nbsp;
      <span className="roofIndicator">{existRoofingPermit ? <i className="fa-solid fa-people-roof"></i> : <></>}</span>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GenerateElligibilityIndicators = (props: GenerateIndicatorsProps) => {

  const [eligibilityResults, setEligibilityResults] = useState<Array<{ insuranceProvider: { name: string }, eligibility: boolean }> | null | undefined>(null);

  useEffect(() => {
    const fetchEligibilityResults = async () => {
      const recordId = props.item.record_id;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set('Content-Type', 'application/json');
      requestHeaders.set('Authorization', props.userToken as string);
      const elegibilityResult = await fetch(`/api/leads/determineEligibility?recordId=${recordId}`, {
        method: 'GET',
        headers: requestHeaders
      });
      const elegibilityResultJson = await elegibilityResult.json();
      const elegibilityResultData = elegibilityResultJson?.response?.result?.data?.eligibilityResults || [];
      setEligibilityResults(elegibilityResultData);
    };
    fetchEligibilityResults();
  }, [props.item.record_id]);

  const calculateVariant = (eligibilityResults: Array<{ insuranceProvider: { name: string }, eligibility: boolean }> | null | undefined) => {
    const containsTrue = eligibilityResults?.some((result) => result.eligibility);
    const containsFalse = eligibilityResults?.some((result) => !result.eligibility);
    if (containsTrue && containsFalse) return 'warning';
    else if (containsTrue) return 'success';
    else return 'danger';
  };


  const elegibilityResultDataMap = eligibilityResults?.map((result: { insuranceProvider: { name: string }, eligibility: boolean }) => {
    return (
      <>
        <Dropdown.Item >
          <div className={`float-start dot ${result.eligibility ? 'dot-green' : 'dot-red'}`}></div>
          <div className="float-start ms-1">{result.insuranceProvider.name}</div>
        </Dropdown.Item>
      </>
    );
  });
  return (

    <DropdownButton className='eligibiltyButton' variant={calculateVariant(eligibilityResults)} title="" id="dropdown-basic">
      {elegibilityResultDataMap}
    </DropdownButton >



  )
}
type PropertyDetailsViewProps = {
  item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null;
};

const PropertyDetailsView = (props: PropertyDetailsViewProps) => {
  return (
    <div id="propertyDetailsView" className="d-flex flex-row">
      <Container>
        <Row>
          <ListGroup as="ol" horizontal>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold">Owner</div>
                {props.item?.deedowner1namefull}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Row>
          <ListGroup as="ul" horizontal>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold">Year Built</div>
                {props.item?.yearbuilt}
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold"># Stories</div>
                {props.item?.storiescount}
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold">Sale Date</div>
                {moment(props.item?.assessorlastsaledate).format('MM/DD/YYYY')}
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold">Sale Amount</div>
                {formatCurrency(Number(props.item?.assessorlastsaleamount))}
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold">Building Area</div>
                {formatNumber(Number(props.item?.areabuilding))} sq ft
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Row>
          <ListGroup as="ol" horizontal>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                <div className="fw-bold">Distance To Coast</div>
                {`${props.item?.coastline?.distanceTo} ${props.item?.coastline?.units}`}
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="">
                <div className="fw-bold">Flood Zone</div>
                {props.item?.flood?.zone}
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
              <div className="">
                <div className="fw-bold">Closest SFHA</div>
                {`${props.item?.flood?.closest100YearFloodZone} ${props.item?.flood?.distanceToClosest100YearFloodZone} ${props.item?.flood?.units}`}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Row>
      </Container>
    </div>
  );
};

enum ProcessingStateEnum {
  'processing',
  'complete',
  'error'
}
const SearchResultResultComponent = (props: SearchResultResultComponentPropTypes) => {
  const [selectedItem, setSelectedItem] = useState<AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [processingState, setProcessingState] = useState<{ [key: string]: ProcessingStateEnum | null | undefined }>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [quotePremium, setQuotePremium] = useState<{ [key: string]: string | null | undefined }>({});

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Array<AgencyPipeInterfaces.AssessorTypes.IAssessorDataType>>([]);

  const [skipTracePhoneNumbers, setSkipTracePhoneNumbers] = useState<Array<{ number: string; phoneType: string }> | null | undefined>(null);
  const [skipTraceEmails, setSkipTraceEmails] = useState<Array<{ email: string; emailType: string }> | null | undefined>(null);

  const [showPermits, setShowPermits] = useState<boolean>(false);
  const [showPhoneNumbers, setShowPhoneNumbers] = useState<boolean>(true);
  const [showEmails, setShowEmails] = useState<boolean>(true);
  const [selectedMapImage, setSelectedMapImage] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (props.selectedPlace == null) return;
    const bestResultIndex = findClosestAddressMatchIndex();
    const myRefLen = refMemo.refs.length;
    const searchResult = props.searchResults?.response?.results || [];

    if (bestResultIndex > -1 && bestResultIndex < myRefLen) {
      const myRefCurrent = refMemo.refs[bestResultIndex]?.current;

      const item = bestResultIndex < searchResult.length ? searchResult[bestResultIndex] : null;
      myRefCurrent?.scrollIntoView(false);
      if (item) handleCardClick(item);
    }
  }, [props.searchResults]);

  const refMemo = useMemo(() => {
    return {
      refs:
        props.searchResults?.response?.results?.map(() => {
          return createRef<HTMLTableRowElement>();
        }) || []
    };
  }, [props.searchResults]);

  const stringify = (obj: unknown) => {
    const cache: Array<unknown> = [];
    const str = JSON.stringify(obj, function (key, value) {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });

    return str;
  };
  const exportData = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const jsonResults = stringify(props.searchResults?.response?.results);
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(jsonResults)}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'searchResults.json';

    link.click();
  };

  const setSelectedItemProcessingState = (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null, processingState: ProcessingStateEnum, label: string) => {
    if (item == null) return;
    const currentTargetId = `${label}_${item.record_id}`;
    setProcessingState((currentState) => ({
      ...currentState,
      ...{ [currentTargetId]: processingState }
    }));
  };
  const sendPostCard = async () => {
    const userToken = props.applicationContext.token || '';
    const item = selectedItem;
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Authorization', userToken);
    setSelectedItemProcessingState(item, ProcessingStateEnum.processing, 'postcard');
    if (!item || !item.quote) {
      await quote(item);
    }
    const leadEntry = await createLeadEntry(item);

    const response = await fetch('/api/leads/sendPostCard', {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(leadEntry)
    });
    const sendPostCardJson = await response.json();

    const getPostcardUrl = async () => {
      const id = sendPostCardJson?.response?.result?.postcard?.id;
      const postgridResponse = await fetch(`/api/leads/getPostCard/${id}`, {
        method: 'GET',
        headers: requestHeaders
      });
      const postgridResponseJson = await postgridResponse.json();
      const url = postgridResponseJson?.response?.result?.postcard?.url;

      return url;
    };

    const getPostCardUrlPromise = (retryCount: number): Promise<string> => {
      return new Promise((resolve, reject) => {
        if (retryCount > 10) {
          reject('unable to retrive postcard url');
        }
        (async () => {
          const url = await getPostcardUrl();
          if (url) {
            resolve(url);
          } else {
            setTimeout(async () => {
              try {
                resolve(await getPostCardUrlPromise(retryCount + 1));
              } catch (e) {
                reject(e);
              }
            }, 5000);
          }
        })();
      });
    };

    if (response.status == 200) {
      try {
        const url = await getPostCardUrlPromise(0);
        const postcardImg = <embed src={`${url}`} width="100%" height="500px"></embed>;
        props.setMainModalData(postcardImg);
        props.setMainShowModal(true);
        setSelectedItemProcessingState(item, ProcessingStateEnum.complete, 'postcard');
      } catch (e) {
        alert('Unable To Extract Postcard URL');
        setSelectedItemProcessingState(item, ProcessingStateEnum.error, 'postcard');
      }
    } else alert(response.statusText);
  };

  const exportSingleLead = async () => {
    const item = selectedItem;
    const requestHeaders = getHeaders();

    const leadEntry = await createLeadEntry(item);

    const response = await fetch('/api/leads/export', {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(leadEntry)
    });

    if (response.status == 200) alert('Export Complete');
    else alert(response.statusText);
  };

  const getPhoneNumber = async () => {
    if (!selectedItem) return;

    if (!selectedItem.skipTrace) {
      const skipTraceResponse = await skipTrace();
      selectedItem.skipTrace = skipTraceResponse.response.result;
    }

    if (selectedItem?.skipTrace) selectedItem.skipTrace.showPhoneNumber = true;
    setSkipTracePhoneNumbers(selectedItem.skipTrace?.phoneNumbers);
  };

  const skipTrace = async () => {
    const item = selectedItem;
    const requestHeaders = getHeaders();
    const leadEntry = createLeadEntry(item);

    const response = await fetch('/api/leads/trace', {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(leadEntry)
    });

    return await response.json();
  };

  const getEmailAddress = async () => {
    if (!selectedItem) return;
    if (!selectedItem.skipTrace) selectedItem.skipTrace = (await skipTrace()).result;

    if (!selectedItem.skipTrace) {
      const skipTraceResponse = await skipTrace();
      selectedItem.skipTrace = skipTraceResponse.response.result;
    }

    if (selectedItem?.skipTrace) selectedItem.skipTrace.showEmail = true;

    setSkipTraceEmails(selectedItem.skipTrace?.emails);
  };

  const handleCardClick = async (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType) => {
    props.handleCardClick(item);
    setSelectedItem(item);
    setShowEmails(true);
    setShowPhoneNumbers(true);
    setSkipTracePhoneNumbers(item.skipTrace?.phoneNumbers);
    setSkipTraceEmails(item.skipTrace?.emails);

    const gooleMapsMetaDataUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?location=${computeLocation(item)}&size=256x150&key=${props.staticMapApiKey}`;

    const googleStreetViewImageUrl = `https://maps.googleapis.com/maps/api/streetview?location=${computeLocation(item)}&size=256x150&key=${props.staticMapApiKey}`;
    const googleArialViewImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${computeLocation(item)}&zoom=18&size=256x150&maptype=satellite&key=${props.staticMapApiKey}`;
    const results = await fetch(gooleMapsMetaDataUrl);
    const jsonResults = await results.json();
    const googleMapsMetaData = jsonResults.status;
    if (googleMapsMetaData == 'ZERO_RESULTS') setSelectedMapImage(googleArialViewImageUrl);
    else setSelectedMapImage(googleStreetViewImageUrl);
  };

  const findClosestAddressMatchIndex = () => {
    const formatedAddress = props.selectedPlace?.formatted_address || '';
    const mapped =
      props.searchResults?.response?.results?.map((result) => {
        return `${result.propertyaddressfull}, ${result.propertyaddresscity}, ${result.propertyaddressstate}, ${result.propertyaddresszip}`;
      }) || [];
    if (mapped.length == 0 || formatedAddress == '') return -1;
    const comparisonResults = stringSimilarity.findBestMatch(formatedAddress, mapped);
    return comparisonResults ? comparisonResults.bestMatchIndex : -1;
  };

  const computeLocation = (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null = null) => {
    if (item == null) item = selectedItem;
    const urlSearchParams = new URLSearchParams({
      location: `${item?.propertyaddressfull},${item?.propertyaddresscity},${item?.propertyaddressstate},${item?.propertyaddresszip}`
    });

    return urlSearchParams.toString();
  };

  const getHeaders = () => {
    const userToken = props.applicationContext.token || '';
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Authorization', userToken);
    return requestHeaders;
  };
  const createLeadEntry = async (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null, autoCreate = false) => {
    const leadEntry = {
      record_id: item?.record_id,
      deedowner1namefull: item?.deedowner1namefull,
      deedowner1namefirst: item?.deedowner1namefirst,
      deedowner1namelast: item?.deedowner1namelast,
      propertyaddressfull: item?.propertyaddressfull,
      propertyaddresscity: item?.propertyaddresscity,
      propertyaddressstate: item?.propertyaddressstate,
      propertyaddresszip: item?.propertyaddresszip,
      assessorlastsaledate: item?.assessorlastsaledate,
      yearbuilt: item?.yearbuilt,
      areabuilding: item?.areabuilding,
      quote: item?.quote
    };

    if (!autoCreate)
      return leadEntry;

    const leadEntryResponse = await fetch('/api/leads', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(leadEntry)
    });

    const leadEntryJsonResponse = await leadEntryResponse.json();
    if (!leadEntryJsonResponse?.response?.success)
      alert('Unable To Create Lead Entry')
    else
      return leadEntryJsonResponse?.response?.result;

  };
  const quote = async (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType | null) => {
    if (item == null) return;
    const currentTargetId = 'quote_' + item.record_id;
    const requestHeaders = getHeaders();

    const leadEntry = await createLeadEntry(item);
    const response = await fetch('/api/quote/flood', {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(leadEntry)
    });

    const currencyFormat = (num: number) => {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const parsedResponse = await response.json();

    if (parsedResponse && parsedResponse.response && parsedResponse.response.status == 'success' && parsedResponse.response.result) item.quote = parsedResponse.response.result;
    else {
      alert('Unable To Process Quote');
      setSelectedItemProcessingState(item, ProcessingStateEnum.error, 'quote');
      setSelectedItemProcessingState(item, ProcessingStateEnum.error, 'postcard');
      return;
    }

    const premium = currencyFormat(Number(parsedResponse?.response?.result?.quotePremium));

    setQuotePremium((currentState) => ({
      ...currentState,
      ...{ [currentTargetId]: premium }
    }));

    return premium;
  };

  const isSelectedItemChecked = (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType) => {
    return selectedItems.indexOf(item) > -1;
  };

  const handleSelectAllItemsCheck = () => {
    const newState = !selectAll;
    setSelectAll(newState);
    if (newState) setSelectedItems(props.searchResults?.response?.results || []);
    else setSelectedItems([]);
  };

  const handleItemCheck = (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType) => {
    let newState = [];
    if (isSelectedItemChecked(item)) newState = selectedItems.filter((i) => i !== item);
    else newState = [...selectedItems, item];

    setSelectedItems(newState);

    setSelectAll(newState.length == props.searchResults?.response?.results?.length);
  };

  const [showCreateAddToCampaign, setShowCreateAddToCampaign] = useState(false);
  const [campaigns, setCampaigns] = useState<Array<AgencyPipeInterfaces.CampaignTypes.ICampaign>>([]);
  const displayAddCreateList = async () => {
    if (selectedItems.length == 0) {
      alert('Please Select Items In Order To Add To A List');
      return;
    }

    const headers = getHeaders();
    const result = await fetch('/api/campaigns', {
      method: 'GET',
      headers: headers
    });

    const jsonResult = await result.json();
    setCampaigns(jsonResult?.response?.result || []);

    setShowCreateAddToCampaign(true);
  };

  const handleOnCreateCampaign = (inputValue: string) => {
    const headers = getHeaders();
    const createCampaign = async () => {
      const result = await fetch('/api/campaigns', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ name: inputValue })
      });

      const jsonResult = await result.json();
      if (jsonResult?.response?.result) {
        setCampaigns([...campaigns, jsonResult?.response?.result]);
        setSelectedCampaign(jsonResult?.response?.result);
      }
    };
    createCampaign();
  };
  const [selectedCampaign, setSelectedCampaign] = useState<AgencyPipeInterfaces.CampaignTypes.ICampaign | null | undefined>(null);
  const handleCampaignSelected = (campaign: SingleValue<AgencyPipeInterfaces.CampaignTypes.ICampaign>) => {
    setSelectedCampaign(campaign);
    alert(`Campaign Selected: ${campaign?.name}`);
  };

  const addDataToList = async () => {
    const requestHeaders = getHeaders();
    const campaignId = selectedCampaign?._id;

    const selectedItemPromiseMap = async () => {
      const selectedItemsMap = selectedItems.map(async (item) => {
        const leadEntry = await createLeadEntry(item);
        return leadEntry;
      });
      return Promise.all(selectedItemsMap);
    }
    const selectedItemsMap = await selectedItemPromiseMap();

    const result = await fetch(`/api/campaigns/${campaignId}/leads/addMany`, {
      headers: requestHeaders,
      method: 'POST',
      body: JSON.stringify(selectedItemsMap)
    });
    if (result.status == 200) {
      setShowCreateAddToCampaign(false);
    } else alert('Error Adding Data To List');
  };

  const [sortState, setSortState] = useState<string | null | undefined>(null);
  const [sortDirectionAssending, setSortDirectionAssending] = useState(true);
  const [showPropertyNotes, setShowPropertyNotes] = useState(false);
  const [selectedItemLead, setSelectedItemLead] = useState<AgencyPipeInterfaces.LeadTypes.ILead | null>(null);
  //const [selectedItemNotes, setSelectedItemNotes] = useState<Array<{ _id: string; note: string; date: Date }> | null | undefined>([]);
  const handleCloseNotes = () => {
    setSelectedItemLead(null);
    setShowPropertyNotes(false);
  };

  const sortResultsBy = (field: string) => {
    const results = props.searchResults?.response?.results || [];
    let localSortDirectionAssending = sortDirectionAssending;
    if (results.length == 0) return;
    if (sortState == field) localSortDirectionAssending = !localSortDirectionAssending;
    else localSortDirectionAssending = true;

    setSortDirectionAssending(localSortDirectionAssending);

    setSortState(field);

    results.sort((a, b) => {
      const aVal = (a as unknown as { [key: string]: string })[field];
      const bVal = (b as unknown as { [key: string]: string })[field];
      if (aVal < bVal && localSortDirectionAssending) return -1;
      else if (aVal < bVal) return 1;
      else if (aVal > bVal && localSortDirectionAssending) return 1;
      else if (aVal > bVal) return -1;
      else return 0;
    });

    props.updateSortedResults(results);
  };

  const loadPropertyNotes = async () => {
    if (selectedItem == null) return;

    const requestHeaders = getHeaders();

    const response = await fetch(`/api/leads/search`, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({ 'data.record_id': selectedItem.record_id })
    });
    const jsonResult = await response.json();
    if (response.status == 200 && jsonResult?.response?.result?.length > 0) {
      setSelectedItemLead(jsonResult.response.result[0]);
    } else {
      const newLeadResult = await createLeadEntry(selectedItem, true);
      setSelectedItemLead(newLeadResult);
    }

  };

  const quoteProperty = async () => {
    setSelectedItemProcessingState(selectedItem, ProcessingStateEnum.processing, 'quote');
    await quote(selectedItem);
    setSelectedItemProcessingState(selectedItem, ProcessingStateEnum.complete, 'quote');
  };
  return (
    <Container fluid id="SearchResultCardGroup" className="d-flex flex-column ">
      <NotesComponent
        showPropertyNotes={showPropertyNotes}
        handleCloseNotes={handleCloseNotes}
        selectedItemLeadEntry={selectedItemLead}
        loadPropertyNotes={loadPropertyNotes}
        selectedItem={selectedItem}
        getHeaders={getHeaders}
      ></NotesComponent>
      <div id="detailViewRow" className="shadow flex-shrink-0" hidden={selectedItem == null}>
        <Container>
          <Row>
            <Container>
              <Row>
                <Col className="text-center mt-1">
                  <h5>
                    {selectedItem?.propertyaddressfull}, {selectedItem?.propertyaddresscity}, {selectedItem?.propertyaddressstate} {selectedItem?.propertyaddresszip}
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">Flood Quote: {quotePremium['quote_' + selectedItem?.record_id]}</div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <div className="shadow ms-auto me-auto" id="staticMapWrapper" style={{ display: `${selectedItem ? '' : 'none'}`, backgroundImage: `url(${selectedMapImage})` }}></div>
                </Col>
                <Col xs={6} className="ps-0">
                  <PropertyDetailsView item={selectedItem}></PropertyDetailsView>
                </Col>
              </Row>
              <Row className="">
                <Col>
                  <FontAwesomeIcon icon={faPhone} title="phone"></FontAwesomeIcon>
                  <Button
                    className="skipTraceData"
                    onClick={(e) => {
                      e.preventDefault();
                      getPhoneNumber();
                      setShowPhoneNumbers(!showPhoneNumbers);
                    }}
                  >
                    Phone Numbers
                  </Button>
                </Col>
              </Row>
              <Row hidden={showPhoneNumbers} className="skipTraceData">
                <Col>
                  <ListGroup horizontal>
                    {skipTracePhoneNumbers?.map((phoneNumbers) => {
                      return (
                        <ListGroup.Item key={phoneNumbers.number}>
                          {phoneNumbers.number} {phoneNumbers.phoneType}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>

              <Row className="">
                <Col>
                  <FontAwesomeIcon icon={faEnvelope} title="email"></FontAwesomeIcon>
                  <Button
                    className="skipTraceData"
                    onClick={(e) => {
                      e.preventDefault();
                      getEmailAddress();
                      setShowEmails(!showEmails);
                    }}
                  >
                    Email Addresses
                  </Button>
                </Col>
              </Row>
              <Row hidden={showEmails} className="skipTraceData">
                <Col>
                  <ListGroup horizontal>
                    {skipTraceEmails?.map((email) => {
                      return <ListGroup.Item key={email.email}>{email.email}</ListGroup.Item>;
                    })}
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </Row>
          <Row>
            <Col>
              <FontAwesomeIcon icon={faBuilding} title="Permits"></FontAwesomeIcon>
              <Button
                className="skipTraceData"
                onClick={() => {
                  setShowPermits(!showPermits);
                }}
              >
                Permits
              </Button>
            </Col>
          </Row>
          <Row hidden={!showPermits}>
            <Col xs={11} className="">
              {
                <ul id="listGroupPermits" className="list-group">
                  {Object.keys(selectedItem ? selectedItem.permits : {}).map((key: string) => {
                    return (
                      <li className="list-group-item" key={key}>
                        <TableViewModelComponent
                          label={key}
                          obj={selectedItem?.permits[key]?.details?.length == 1 ? selectedItem?.permits[key]?.details[0] : { permits: selectedItem?.permits[key]?.details }}
                        ></TableViewModelComponent>
                      </li>
                    );
                  })}
                </ul>
              }
            </Col>
          </Row>
          <Row>
            <Container className="text-center">
              <Row>
                <Col>
                  <FontAwesomeIcon icon={faUsps} title="postcard"></FontAwesomeIcon>
                  <Button
                    disabled={processingState['postcard_' + selectedItem?.record_id] == ProcessingStateEnum.processing}
                    onClick={(e) => {
                      e.preventDefault();
                      sendPostCard();
                    }}
                  >
                    Postcard{' '}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      hidden={processingState['postcard_' + selectedItem?.record_id] != ProcessingStateEnum.processing}
                      className="ms-1"
                    />
                  </Button>
                </Col>
                <Col>
                  <FontAwesomeIcon icon={faFileExport} title="export"></FontAwesomeIcon>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      exportSingleLead();
                    }}
                  >
                    CRM
                  </Button>
                </Col>
                <Col>
                  <FontAwesomeIcon icon={faComment} title="Notes"></FontAwesomeIcon>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPropertyNotes(true);
                    }}
                  >
                    Notes
                  </Button>
                </Col>
                <Col>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} title="Quote"></FontAwesomeIcon>
                  <Button
                    disabled={processingState['quote_' + selectedItem?.record_id] == ProcessingStateEnum.processing}
                    onClick={(e) => {
                      e.preventDefault();
                      quoteProperty();
                    }}
                  >
                    Quote{' '}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      hidden={processingState['quote_' + selectedItem?.record_id] != ProcessingStateEnum.processing}
                      className="ms-1"
                    />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>

      <div id="resultsDisplayTableRow" className='mt-1 flex-grow-1 overflow-auto"'>
        <table id="resultDisplayTable" className="text-start card-table table table-striped responsive">
          <thead>
            <tr>
              <th>
                <input
                  className="ms-1"
                  type="checkbox"
                  checked={selectAll}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleSelectAllItemsCheck();
                  }}
                ></input>
              </th>
              <th>Tags</th>
              <th>Eligible</th>
              <th
                className={`block sortable`}
                onClick={(e) => {
                  e.preventDefault();
                  sortResultsBy('propertyaddressfull');
                }}
              >
                Address<span className={sortState == 'propertyaddressfull' ? (sortDirectionAssending ? 'chevron chevron-up' : 'chevron') : ''}></span>
              </th>
              <th
                className={`block sortable`}
                onClick={(e) => {
                  e.preventDefault();
                  sortResultsBy('yearbuilt');
                }}
              >
                Year Built<span className={sortState == 'yearbuilt' ? (sortDirectionAssending ? 'chevron chevron-up' : 'chevron') : ''}></span>
              </th>
              <th
                className={`block sortable`}
                onClick={(e) => {
                  e.preventDefault();
                  sortResultsBy('areabuilding');
                }}
              >
                Sq Feet<span className={sortState == 'areabuilding' ? (sortDirectionAssending ? 'chevron chevron-up' : 'chevron') : ''}></span>
              </th>
              <th
                className={`block sortable`}
                onClick={(e) => {
                  e.preventDefault();
                  sortResultsBy('assessorlastsaleamount');
                }}
              >
                Sale Price<span className={sortState == 'assessorlastsaleamount' ? (sortDirectionAssending ? 'chevron chevron-up' : 'chevron') : ''}></span>
              </th>
              <th
                className={`block sortable`}
                onClick={(e) => {
                  e.preventDefault();
                  sortResultsBy('assessorlastsaledate');
                }}
              >
                Sale Date<span className={sortState == 'assessorlastsaledate' ? (sortDirectionAssending ? 'chevron chevron-up' : 'chevron') : ''}></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {props?.searchResults?.response?.results?.map((item, idx) => {
              item?.marker?.addListener('click', () => {
                handleCardClick(item);
              });

              const tmpRef = refMemo?.refs[idx];

              return (

                <><tr
                  className={`mt-1 mb-1 ${item === selectedItem ? 'selectedRow' : ''}`}
                  ref={tmpRef}
                  key={item.record_id}
                  onClick={() => {
                    handleCardClick(item);
                  }}
                >

                  <td>
                    <input
                      className="ms-1"
                      type="checkbox"
                      checked={isSelectedItemChecked(item)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleItemCheck(item);
                      }}
                    ></input>
                  </td>
                  <td className="indicatorTd">
                    <GenerateIndicators item={item}></GenerateIndicators>
                  </td>
                  <td>
                    <GenerateElligibilityIndicators item={item} userToken={props.applicationContext.token}></GenerateElligibilityIndicators>
                  </td>
                  <td>
                    {item.propertyaddressfull}, {item.propertyaddresscity}, {item.propertyaddressstate}
                  </td>
                  <td>{item.yearbuilt}</td>
                  <td>{Number(item.areabuilding).toLocaleString('en', { useGrouping: true })}</td>
                  <td>
                    {Number(item.assessorlastsaleamount).toLocaleString('en', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0
                    })}
                  </td>
                  <td>{moment(item?.assessorlastsaledate).format('MM/DD/YYYY')}</td>

                </tr></>
              );
            })}
          </tbody>
        </table>
      </div>
      <Row id="resultActionRow" className="flex-shrink-0">
        <Col>
          <Button
            onClick={(e) => {
              e.preventDefault();
              exportData();
            }}
          >
            Download
          </Button>
        </Col>
        <Col>
          <Button id="displayAddCreateList" onClick={displayAddCreateList}>
            Add To List
          </Button>
        </Col>
        <Col>
          <Button id="displayAddCreateList" onClick={() => props.setShowCampaigns(true)}>
            Contact
          </Button>
        </Col>
      </Row>
      <Modal show={showCreateAddToCampaign} onHide={() => setShowCreateAddToCampaign(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Or Add To List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatableSelect
            getOptionLabel={(option) => option.name || `Create New List...`}
            getOptionValue={(option) => option._id}
            value={selectedCampaign}
            isClearable
            options={campaigns}
            onCreateOption={handleOnCreateCampaign}
            onChange={handleCampaignSelected}
          ></CreatableSelect>
          <Container fluid>
            <Row className="d-flex align-items-center">
              <Col>
                <Button className="float-start" onClick={() => setShowCreateAddToCampaign(false)}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button className="float-end" onClick={() => addDataToList()}>
                  Add
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </Container >
  );
};

type SearchResultResultComponentPropTypes = {
  token: string | null;
  searchResults: SearchResultResponseType | null;
  handleCardClick: (item: AgencyPipeInterfaces.AssessorTypes.IAssessorDataType) => void;
  staticMapApiKey: string;
  applicationContext: ApplicationContextType;
  selectedPlace: google.maps.places.PlaceResult | null;
  setMainShowModal: (val: boolean) => void;
  setMainModalData: (children: ReactNode) => void;
  setShowCampaigns: (val: boolean) => void;
  updateSortedResults: (results: Array<AgencyPipeInterfaces.AssessorTypes.IAssessorDataType>) => void;
};
export default SearchResultResultComponent;
