import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import './Form5500Filter.css';

const Form500Filter = (props) => {
  const [form500SearchAttributes, setForm500SearchAttributes] = useState({
    numberOfEmployees: {min: null, max: null},
    renewalDate: {min: null, max: null},
    companyName: null,
    ein: null,
    naicCode: null,
    sicCode: null,
    planAdmin: null,
    lateOrDelinquent: null,
    insuranceLines: null,
    carrier: null,
  });

  /**
   *
   * @param {*} e
   */
  function handleNumberOfEmployeeChange(e) {
    const updatedValue = form500SearchAttributes.numberOfEmployees;
    updatedValue[e.target.name] = e.target.value;
    setForm500SearchAttributes((form5500Attributes) => ({
      ...form5500Attributes,
      ...updatedValue,
    }));
  }

  /**
   *
   * @param {*} e
   */
  function handleGenericForm5500Update(e) {
    const updatedItem = {
      [e.target.name]: e.target.value,
    };

    setForm500SearchAttributes((form5500Attributes) => ({

      ...form5500Attributes,
      ...updatedItem,
    }));
  }

  useEffect(() => {
    props.onStateChange({
      form5500Filter: form500SearchAttributes});
  }, [form500SearchAttributes]);


  return (
         <Container fluid>

          <Row className='mt-3'>
            <Col xs={5} className='text-start'>
            <span>Current Carrier:</span>
            </Col>
            <Col className='ps-0'>
            <input type="text" className='form5500Input' name="carrier" onChange={handleGenericForm5500Update}></input>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={5} className='text-start'><span>Renewal Date</span></Col>
            <Col><Form.Control className='form5500InputDatePicker' type="date" name="min" /></Col>
            <Col><Form.Control className='form5500InputDatePicker float-right' type="date" name="max" /></Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={5} className='text-start'> <span>NAIC Code</span></Col>
           <Col>
            <input className='form5500Input' type="text" name="naicCode" onChange={handleGenericForm5500Update}></input>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={5} className='text-start'><span>Plan Admin</span></Col>
            <Col><input className='form5500Input' type="text" name="planAdmin" onChange={handleGenericForm5500Update}></input></Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={5} className='text-start'><span>Insurance Lines</span></Col>
            <Col><input className='form5500Input' type="text" name="insuranceLines"></input></Col>
          </Row>
          <Row className='mt-3 mb-3'>

            <Col xs={5} className='text-start'><span className="">Number Of Employees</span></Col>
           
           
               <Col>
              <Form.Select name='min' aria-label="Number Of Employees min" id="number-of-employees-min" size="sm" onChange={handleNumberOfEmployeeChange}>
                <option value="-1">No Min</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="5000">5000</option>
              </Form.Select >
              </Col>
              <Col>
              <Form.Select name="max" id="number-of-employees-max" aria-label="Number Of Employee max" size="sm" onChange={handleNumberOfEmployeeChange} >
                <option value="-1">No Max</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="5000">5000</option>

              </Form.Select>
              </Col>
          

          </Row>

      </Container>);
};

Form500Filter.propTypes = {
  onStateChange: PropTypes.func,
  visible: PropTypes.bool.isRequired,
};


export default Form500Filter;
