import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { TabViewEnum } from './SignUpSignIn';

type NavBarPropTypes = {
    handleShow: (defaultView: TabViewEnum) => void
}
function NavBar(props: NavBarPropTypes) {

    return (<Row id="navBarHeaderContainer" className="fixed-top">
        <Navbar expand="lg" style={{ boxShadow: 'unset' }}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-auto' />
            <Navbar.Collapse id="basic-navbar-nav">
                <Container>
                    <Row>
                        <Col xs={3}>
                            <Navbar.Brand href="#home">
                                <img src="/img/agency_pipe_logo.jpg" width="242" height="92" className="d-inline-block align-top float-start responsive" alt="Agency Pipe" />
                            </Navbar.Brand>
                        </Col>

                        <Col xs={6} className='justify-content-center' style={{ display: 'flex' }}>
                            <Nav className='justify-content-center'>
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#features">Features</Nav.Link>
                                <Nav.Link href="#pricing">Pricing</Nav.Link>
                                <Nav.Link href="#faq">FAQ</Nav.Link>
                                <Nav.Link href="#about-us">About Us</Nav.Link>
                            </Nav>
                        </Col>
                        <Col xs={3} className='justify-content-center d-none d-lg-flex'>
                            <Button className='normal-case' color="primary" variant="text" onClick={(e) => { e.preventDefault(); props.handleShow(TabViewEnum.signin); }}>
                                Sign In
                            </Button>
                        </Col>
                    </Row>
                    <Row className='d-lg-none'>
                        <Col>
                            <Button className='normal-case' color="primary" variant="text" onClick={(e) => { e.preventDefault(); props.handleShow(TabViewEnum.signin); }}>
                                Sign In
                            </Button>
                        </Col>

                    </Row>
                </Container>
            </Navbar.Collapse>
        </Navbar>
    </Row>)
}
export default NavBar